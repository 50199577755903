// @ts-nocheck
import React, { useState, useContext } from 'react';
import PaymentModel from '../models/PaymentModel';
import { LOCAL_STORAGE_PAYMENT } from '../helpers/constants';

const PaymentContext = React.createContext(null);
const PaymentUpdateContext = React.createContext<(payment: PaymentModel | undefined) => void | null>(null);

export function usePayment(): PaymentModel | undefined {
    return useContext(PaymentContext);
}

export function useUpdatePayment(): (payment: PaymentModel | undefined) => void {
    return useContext(PaymentUpdateContext);
}

export function PaymentProvider({ children }) {
    const [payment, setPayment] = useState<PaymentModel | undefined>();

    function updatePayment(newPayment: PaymentModel | undefined) {
        localStorage.setItem(LOCAL_STORAGE_PAYMENT, JSON.stringify(newPayment));
        setPayment(newPayment);
    }

    return (
        <PaymentContext.Provider value={payment}>
            <PaymentUpdateContext.Provider value={updatePayment}>
                {children}
            </PaymentUpdateContext.Provider>
        </PaymentContext.Provider>
    );
}