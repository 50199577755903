import React, { Component, ReactNode } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import ErrorBoundary from './shared/ErrorBoundary';
import toast, { Toaster } from 'react-hot-toast';

interface Props {
    children?: ReactNode;
}

const Layout = (props: Props) => {
    return (
        <div style={{ "paddingBottom": "200px" }}>
            <Toaster position="top-center" />
            <ErrorBoundary>
                <NavMenu />
            </ErrorBoundary>
            <ErrorBoundary>
                <div style={{ "padding": "0" }}>
                    {props.children}
                </div>
            </ErrorBoundary>
        </div>
    );

}

export default Layout;
