import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { useUpdateCurrentUser } from "../../contexts/UserContext";
import { setAuth } from "../../helpers/authHelper";
import CurrentUserModel from "../../models/CurrentUserModel";
import LoginResponseModel from "../../models/LoginResponseModel";
import GetCodeResponseModel from "../../models/GetCodeResponseModel";
import Loading from "../shared/Loading";

const TeamLogin = () => {
    //
    // Context
    //
    const updateCurrentUser = useUpdateCurrentUser();
    //
    // Constants
    //
    const navigate = useNavigate();
    //
    // State
    //
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState<boolean>();
    const [emailMessage, setEmailMessage] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [teamCode, setTeamCode] = useState<string>('');
    const [sendingCode, setSendingCode] = useState<boolean>(false);

    const validate = () => {
        return email.length;
    };

    //
    // Event Handlers
    //
    const handleCodeInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setTeamCode(e.currentTarget.value);

        // If code is 4 characters long, submit the code
        if (e.currentTarget.value.length == 4) {
            setAuthenticating(true);

            // Post code to the server
            fetch('/api/Auth/Login/TeamLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code: e.currentTarget.value })
            })
                //
                // Handle invalid json response
                //
                .then(async (response) => {
                    if (!response.ok) {
                        setAuthenticating(false);
                        setError(true);
                        setMessage(await response.text());
                        throw new Error("Invalid response from server");
                    }

                    return response.json();
                })
                .then((data: LoginResponseModel) => {
                    var user = new CurrentUserModel();
                    user.email = data.email;
                    user.roles = data.roles;
                    user.teamId = data.teamId;
                    user.name = data.name;
                    user.teamHasPaid = data.teamHasPaid;

                    if (data.roles.includes('Admin')) {
                        user.isAdmin = true;
                    }

                    updateCurrentUser(user, 'TeamLogin.tsx');

                    setAuth(data.email, data.jwt, data.validTo);
                    navigate("/live-scoring");
                });
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleGetCodeClick = () => {
        setSendingCode(true);

        // Post email
        fetch('/api/Auth/Login/GetCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
            //
            // Handle invalid json response
            //
            .then(response => {
                setSendingCode(false);

                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then((data: GetCodeResponseModel) => {
                setEmailValid(data.isSuccessful);
                setEmailMessage(data.message);
            });
    }

    if(authenticating) {
        return <Loading message="Authenticating..." />
    }

    if(sendingCode) {
        return <Loading message="Sending code..." />
    }

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2 mt-2">
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3 bg-success text-bg-success">
                    <h4 className="my-0 fw-normal">Know Your Team Code?</h4>
                </div>
                <div className="card-body">
                    {/* <h3 className="card-title pricing-card-title">Go to live scoring</h3> */}
                    <input 
                        type="text" 
                        style={{ "fontSize": "16px" }} 
                        className="form-control" 
                        placeholder="Enter your team code" 
                        onKeyUp={handleCodeInput}
                        defaultValue={teamCode} 
                    />
                    {error && <div className="alert alert-danger mt-2" style={{ "marginBottom": "0" }} role="alert">
                        {message}
                    </div>}
                </div>
            </div>
            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3 bg-dark text-bg-dark">
                    <h4 className="my-0 fw-normal">Forgot Your Team Code?</h4>
                </div>
                <div className="card-body">
                    <h3 className="card-title pricing-card-title">Request it in an email</h3>
                    <p>
                        <input name="email" type="email" style={{ "fontSize": "16px" }} value={email} onChange={handleEmailChange} className="form-control" placeholder="Team leader email" />
                    </p>
                    <button className="btn btn-primary w-100" onClick={handleGetCodeClick} disabled={!validate()}>Get Code</button>
                    {emailValid && (emailMessage != '') && <div className="bg-success mt-3 rounded align-items-center p-1 text-center">
                        <span className="text-white align-middle">{emailMessage}</span>
                    </div>}
                    {!emailValid && (emailMessage != '') && <div className="bg-danger mt-3 rounded align-items-center p-1 text-center">
                        <span className="text-white align-middle">{emailMessage}</span>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default TeamLogin;