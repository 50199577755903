import { ChangeEvent, useEffect, useState } from 'react';
import { useCurrentUser } from './../../contexts/UserContext';
import { useEvent } from './../../contexts/EventContext';
import Loading from '../shared/Loading';
import { getAuthHeader } from '../../helpers/authHelper';
import { showErrorAlert } from '../../helpers/alertHelper';
import VolunteerModel from '../../models/VolunteerModel';
import Swal from 'sweetalert2';

const Volunteers = () => {
    //
    // Context
    //
    const currentUser = useCurrentUser();
    const event = useEvent();
    //
    // State
    //
    const [volunteers, setVolunteers] = useState<VolunteerModel[]>([]);
    const [visibleVolunteers, setVisibleVolunteers] = useState<VolunteerModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [newVolunteer, setNewVolunteer] = useState({ firstName: '', lastName: '', shirtSize: 'Small' });
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editedVolunteer, setEditedVolunteer] = useState({ volunteerId: 0, firstName: '', lastName: '', shirtSize: 'Small' });

    //
    // Use effect
    //
    useEffect(() => {
        const fetchVolunteers = async () => {
            try {
                const resp = await fetch("/api/admin/get-volunteers", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getAuthHeader(),
                    }
                });

                if (resp.status !== 200) {
                    showErrorAlert();
                    console.error(resp);
                    return;
                }

                const data = await resp.json();
                setVolunteers(data.volunteers);
                setVisibleVolunteers(data.volunteers);
            } catch {
                showErrorAlert();
            } finally {
                setLoading(false);
            }
        };

        fetchVolunteers();
    }, []);

    const refreshVolunteers = async () => {
        setLoading(true);
        try {
            const resp = await fetch("/api/admin/get-volunteers", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getAuthHeader(),
                }
            });

            if (resp.status !== 200) {
                showErrorAlert();
                console.error(resp);
                return;
            }

            const data = await resp.json();
            setVolunteers(data.volunteers);
            setVisibleVolunteers(data.volunteers);
        } catch {
            showErrorAlert();
        } finally {
            setLoading(false);
        }
    };

    //
    // Handle search
    //
    function handleSearch(e: ChangeEvent<HTMLInputElement>): void {
        const search = e.target.value.toLowerCase();
        const filteredVolunteers = volunteers.filter((volunteer) => {
            return volunteer.fullName?.toLowerCase().includes(search);
        });
        setVisibleVolunteers(filteredVolunteers);
    }

    // Handle adding new volunteer
    const handleAddVolunteer = () => {
        if (!newVolunteer.firstName || !newVolunteer.lastName || !newVolunteer.shirtSize) {
            return;
        }

        try {
            fetch("/api/admin/add-volunteer", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getAuthHeader(),
                },
                body: JSON.stringify(newVolunteer)
            }).then(() => {
                refreshVolunteers();
                setNewVolunteer({ firstName: '', lastName: '', shirtSize: 'Small' });
            });

        } catch {
            showErrorAlert();
        }
    };

    // Handle editing volunteer
    const handleEditVolunteer = (volunteer: VolunteerModel) => {
        setEditIndex(visibleVolunteers.indexOf(volunteer));
        setEditedVolunteer({
            volunteerId: volunteer.volunteerId,
            firstName: volunteer.firstName || '',
            lastName: volunteer.lastName || '',
            shirtSize: volunteer.shirtSize || 'Small'
        });
    };

    // Handle save edit
    const handleSaveEdit = () => {
        if (editIndex === null) {
            return; // No volunteer selected for editing
        }

        const updatedVolunteer = {
            ...visibleVolunteers[editIndex], // Using editIndex to get the correct volunteer
            ...editedVolunteer
        };

        try {
            fetch(`/api/admin/edit-volunteer`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getAuthHeader(),
                },
                body: JSON.stringify(updatedVolunteer)
            }).then(() => {
                refreshVolunteers();
                setEditIndex(null);
            });
        } catch {
            showErrorAlert();
        }
    };

    // Handle delete volunteer
    const handleDeleteVolunteer = (index: number) => {
        const volunteerId = visibleVolunteers[index].volunteerId;

        Swal.fire({
            title: 'Are you sure?',
            text: "This will permanently delete the Volunteer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    fetch(`/api/admin/delete-volunteer/${volunteerId}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': getAuthHeader(),
                        }
                    }).then(() => {
                        refreshVolunteers();
                    });


                } catch {
                    showErrorAlert();
                }
            }
        })
    };

    // Handle input change for new volunteer and edited volunteer
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, isEdit: boolean = false) => {
        const { name, value } = e.target;
        if (isEdit) {
            setEditedVolunteer({ ...editedVolunteer, [name]: value });
        } else {
            setNewVolunteer({ ...newVolunteer, [name]: value });
        }
    };

    return (
        <div className="container p-3">
            <h2 className="text-center">Volunteers</h2>

            {loading && <Loading />}

            <div className="my-3 row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text search-input-icon py-2">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <input
                            className="form-control py-2 border-right-0 border"
                            type="search"
                            placeholder="Search"
                            onChange={(e) => { handleSearch(e) }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Shirt Size</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {visibleVolunteers && visibleVolunteers.map((volunteer, index) => (
                            <tr key={index}>
                                {editIndex === index ? (
                                    <>
                                        <td><input className="form-control" type="text" name="firstName" value={editedVolunteer.firstName} onChange={(e) => handleInputChange(e, true)} /></td>
                                        <td><input className="form-control" type="text" name="lastName" value={editedVolunteer.lastName} onChange={(e) => handleInputChange(e, true)} /></td>
                                        <td>
                                            <select className="form-control" name="shirtSize" value={editedVolunteer.shirtSize} onChange={(e) => handleInputChange(e, true)}>
                                                <option value="Small">Small</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Large">Large</option>
                                                <option value="XL">XL</option>
                                                <option value="XXL">XXL</option>
                                                <option value="XXXL">XXXL</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => handleSaveEdit()}>Save</button>
                                            <button className="btn btn-danger" onClick={() => setEditIndex(null)}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{volunteer.firstName}</td>
                                        <td>{volunteer.lastName}</td>
                                        <td>{volunteer.shirtSize}</td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => handleEditVolunteer(volunteer)}>Edit</button>
                                            <button className="btn btn-danger" onClick={() => handleDeleteVolunteer(index)}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        <tr>
                            <td><input className="form-control" type="text" name="firstName" value={newVolunteer.firstName} onChange={handleInputChange} /></td>
                            <td><input className="form-control" type="text" name="lastName" value={newVolunteer.lastName} onChange={handleInputChange} /></td>
                            <td>
                                <select className="form-control" name="shirtSize" value={newVolunteer.shirtSize} onChange={handleInputChange}>
                                    <option value="Small">Small</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Large">Large</option>
                                    <option value="XL">XL</option>
                                    <option value="XXL">XXL</option>
                                    <option value="XXXL">XXXL</option>
                                </select>
                            </td>
                            <td>
                                <button className="btn btn-success m-1"
                                    onClick={handleAddVolunteer}
                                    disabled={!newVolunteer.firstName || !newVolunteer.lastName || !newVolunteer.shirtSize}
                                >
                                    Add
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Volunteers;
