import { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import AttendeeSignUpRequest, { AttendeeRequest, ShirtRequest } from "./../../models/AttendeeSignUpRequest";
import CurrentUserModel from "../../models/CurrentUserModel";
import Loading from '../shared/Loading';
import { useUpdatePayment } from '../../contexts/PaymentContext';
import { useEvent } from '../../contexts/EventContext';
import { validateEmail } from '../../helpers/emailHelper';
import { showErrorAlert } from "../../helpers/alertHelper";
import PaymentModel from "../../models/PaymentModel";
import Swal from 'sweetalert2';

const DinnerSignUp = () => {
    //
    // Context
    //
    const navigate = useNavigate();
    const currentEvent = useEvent();
    const updatePayment = useUpdatePayment();

    //
    // State
    //
    const [isStep1, setIsStep1] = useState(true);
    const [isStep2, setIsStep2] = useState(false);
    const [requestedBy, setRequestedBy] = useState<string>('');
    const [requestedByFullName, setRequestedByFullName] = useState<string>('');
    const [attendees, setAttendees] = useState([{
        id: Date.now(),
        name: ''
    }]);

    const MAX_ATTENDEES = 6;
    const MAX_SHIRTS = 6;

    const [sizes, setSizes] = useState([
        { id: 1, size: 'Small', quantity: 0 },
        { id: 2, size: 'Medium', quantity: 0 },
        { id: 3, size: 'Large', quantity: 0 },
        { id: 4, size: 'X-Large', quantity: 0 },
        { id: 5, size: 'XX-Large', quantity: 0 },
        { id: 6, size: 'XXX-Large', quantity: 0 }
    ]);

    const addAttendeesDisabled = attendees.length >= MAX_ATTENDEES;
    const canAddShirts = sizes.reduce((acc, size) => acc + size.quantity, 0) < MAX_SHIRTS;
    const emailIsValid = validateEmail(requestedBy!);
    const paymentButtonDisabled = !isStep2 || !requestedByFullName || requestedByFullName.trim().length === 0 || !requestedBy || requestedBy.trim().length === 0 || !emailIsValid || emailIsValid.length <= 0;

    const getValidAttendees = () => {
        //
        // Get attendees with non-blank names
        //
        return attendees.filter(attendee => attendee.name.trim() !== '');
    }

    const renderAttendedList = () => {
        const validAttendees = getValidAttendees();

        return (
            <ul style={{ fontSize: '1.5em' }}>
                {validAttendees.map((attendee) => {
                    return (

                        <li key={attendee.id}>
                            {attendee.name} (${currentEvent.dinnerFee})
                        </li>
                    )
                })}
            </ul>
        )
    }

    const getValidSizes = () => {
        //
        // Get sizes that have a quantity greater than zero
        //
        return sizes.filter(size => size.quantity > 0);
    }

    const renderSizeList = () => {
        const validSizes = getValidSizes();

        return (
            <ul style={{ fontSize: '1.5em' }}>
                {validSizes.map((size) => {
                    return (
                        <li key={size.id}>
                            {size.size} - {size.quantity} (${size.quantity * currentEvent.shirtFee})
                        </li>
                    )
                })}
            </ul>
        );
    }

    const getTotalAmountOwed = () => {
        const validAttendees = getValidAttendees();
        const validShirts = getValidSizes();

        const attendeeFee = validAttendees.length * currentEvent.dinnerFee;
        const shirtFee = validShirts.reduce((acc, size) => acc + size.quantity, 0) * currentEvent.shirtFee;

        return attendeeFee + shirtFee;
    }

    const processRequest = async () => {
        const request = new AttendeeSignUpRequest();
        request.attendees = new Array<AttendeeRequest>();
        request.shirts = new Array<ShirtRequest>();

        const validAttendees = getValidAttendees();

        validAttendees.forEach((attendee) => {
            const model = new AttendeeRequest();
            model.name = attendee.name;
            request.attendees.push(model);
        });

        const validSizes = getValidSizes();

        validSizes.forEach((size) => {
            const model = new ShirtRequest();
            model.name = requestedByFullName;
            model.size = size.size;
            model.quantity = size.quantity;
            request.shirts.push(model);
        })

        const postBody = {
            eventId: currentEvent.eventId,
            requestedByEmailAddress: requestedBy,
            attendees: request.attendees,
            shirtRequests: request.shirts
        }

        const response = await fetch('/api/signup/attendee-sign-up', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });

        if (response.status !== 200) {
            //
            // Server error message
            //
            showErrorAlert();
            return;
        }

        const json = await response.json();

        const newPayment = new PaymentModel();
        newPayment.isAttendeeRequest = true;
        newPayment.attendeeRequest = request;
        newPayment.paymentTrackingId = json.paymentTrackingId;
        newPayment.requestedByEmail = requestedBy;
        updatePayment(newPayment);

        navigate("/payment");
    }

    const openShirtModal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        Swal.fire({
            imageUrl: '/images/2024-skramble-shirt.jpg',
            imageAlt: 'Shirt',
            showCloseButton: false,
            showConfirmButton: false
        });
    }

    return (
        <div className="container pt-4">

            <div className="row">
                <div className="col-8">
                    <h2 className="text-dark">Dinner Sign Up</h2>
                </div>
                <div className="col-4 text-end pb-2">
                    <Link to="/" className="btn btn-primary">
                        <i className="bi bi-house"></i> Home
                    </Link>
                </div>
                <hr />
            </div>
            {isStep1 && <div className="row">
                <div className="col-12">
                    <div className="alert alert-info">
                        If you do <b>NOT</b> plan on golfing, but still want to attend the dinner, or order a shirt, please complete the following form.
                        {currentEvent && <ul style={{ marginTop: '12px', marginBottom: '0' }}>
                            <li><b>Attendees</b> - ${currentEvent.dinnerFee} per person</li>
                            <li><b>Shirts</b> - ${currentEvent.shirtFee} per shirt</li>
                        </ul>}
                    </div>
                </div>
            </div>}
            {isStep1 && <>
                <div className="row mb-2">
                    <div className="col-12 col-lg-6 pe-0 pe-lg-5 pb-5 pb-lg-2">
                        <h2>Attendees</h2>
                        {attendees.map((attendee, index) => {
                            let classes = "row mb-2";

                            if (index % 2 == 0) {
                                classes += " bg-light";
                            }

                            return (
                                <div key={attendee.id} className={classes}>
                                    <div className="flex space-between" style={{ alignItems: 'center' }}>
                                        <div className="px-1" style={{ fontSize: '1.5em' }}>{(index + 1)}</div>
                                        <div className="px-3" style={{ flexGrow: '1' }}>
                                            <div className="form-floating my-2">
                                                <input
                                                    className="form-control"
                                                    style={{ fontSize: '1.5em' }}
                                                    defaultValue={attendee.name}
                                                    onChange={(e) => {
                                                        const newAttendees = [...attendees];
                                                        newAttendees[index].name = e.target.value;
                                                        setAttendees(newAttendees);
                                                    }}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') {
                                                            if (attendees.length >= MAX_ATTENDEES) {
                                                                return;
                                                            }

                                                            setAttendees([...attendees, { id: Date.now(), name: '' }]);
                                                        }
                                                    }}
                                                />
                                                <label className="form-label">Full Name</label>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="text-danger text-lg-center px-1"
                                                style={{ "cursor": "pointer", fontSize: '1.7em' }}
                                                onClick={() => {
                                                    const attendeeIndex = index;
                                                    const newAttendees = [...attendees];
                                                    newAttendees.splice(attendeeIndex, 1);
                                                    setAttendees(newAttendees);
                                                }}
                                            >
                                                <i className="bi bi-trash3"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="mt-2 row">
                            <button
                                className="btn btn-primary btn-tall w-100"
                                disabled={addAttendeesDisabled}
                                onClick={() => {
                                    if (attendees.length >= MAX_ATTENDEES) {
                                        return;
                                    }

                                    setAttendees([...attendees, { id: Date.now(), name: '' }]);
                                }}
                            >
                                <i className="bi bi-plus-square"></i> Add Attendee
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h2>Request Shirts</h2>
                        <a className="pb-3 d-inline-block" href="#" onClick={openShirtModal}>Check out this year's shirt!</a>
                        {sizes.map((size, index) => {
                            let classes = "row text-center align-center mt-2";

                            if (index % 2 === 0) {
                                classes += " bg-light";
                            }

                            let increaseClasses = "bi bi-plus-circle clickable";

                            if (!canAddShirts) {
                                increaseClasses += " d-none";
                            }

                            return (
                                <div key={size.id} className={classes}>
                                    <div className="col-4">
                                        <i
                                            className="bi bi-dash-circle clickable"
                                            style={{ fontSize: '2em' }}
                                            onClick={() => {
                                                const newSize = [...sizes];

                                                if (newSize[index].quantity > 0) {
                                                    newSize[index].quantity = newSize[index].quantity - 1;
                                                    setSizes(newSize);
                                                }
                                            }}
                                        ></i>
                                    </div>
                                    <div className="col-4" style={{ fontSize: '1.5em' }}>
                                        <div>
                                            {size.size}
                                        </div>
                                        <div>
                                            {size.quantity}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <i
                                            className={increaseClasses}
                                            style={{ fontSize: '2em' }}
                                            onClick={() => {
                                                const newSize = [...sizes];

                                                if (newSize[index].quantity < MAX_SHIRTS) {
                                                    newSize[index].quantity = newSize[index].quantity + 1;
                                                    setSizes(newSize);
                                                }
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </div>
                <div className="row mt-2">
                    <div
                        className="btn btn-success btn-tall w-100"
                        onClick={() => {
                            setIsStep1(false);
                            setIsStep2(true);
                        }}
                    >Confirm Selections</div>
                </div>
            </>}
            {isStep2 && <>
                {getValidAttendees().length > 0 && <div className="row mt-2">
                    <div className="col-12">
                        <h2>Attendees</h2>
                        {renderAttendedList()}
                    </div>
                </div>}
                {getValidSizes().length > 0 && <div className="row mt-2">
                    <div className="col-12">
                        <h2>Shirts</h2>
                        {renderSizeList()}
                    </div>
                </div>}
                <div className="row mt-2">
                    <div className="col-12">
                        <h2>Total Owed</h2>
                        <div style={{ fontSize: '1.5em' }}>
                            ${getTotalAmountOwed()}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-lg-6">
                        <div className="alert alert-info">
                            A name and email address are needed to send a payment receipt
                        </div>
                        <div className="form-floating my-2">
                            <input
                                className="form-control"
                                style={{ fontSize: '1.5em' }}
                                defaultValue={requestedByFullName}
                                onChange={(e) => {
                                    setRequestedByFullName(e.target.value);
                                }}
                            />
                            <label className="form-label">Full Name</label>
                        </div>
                        <div className="form-floating my-2">
                            <input
                                className="form-control"
                                style={{ fontSize: '1.5em' }}
                                defaultValue={requestedBy}
                                onChange={(e) => {
                                    setRequestedBy(e.target.value);
                                }}
                            />
                            <label className="form-label">Email Address</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                        <button
                            disabled={paymentButtonDisabled}
                            className="btn btn-success btn-tall w-100"
                            onClick={() => {
                                processRequest();
                            }}
                        >Proceed to Payment</button>
                    </div>
                    
                </div>
                <div className="row mt-2">
                    <div className="col-12 col-lg-6">
                        <div
                            className="btn btn-danger btn-tall w-100"
                            onClick={() => {
                                setIsStep1(true);
                                setIsStep2(false);
                            }}
                        >Edit Selections</div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default DinnerSignUp;