import Swal from 'sweetalert2';

function showErrorAlert(title?: string, text?: string) {
    Swal.fire({
        icon: 'error',
        title: title ? title : 'Oops...',
        text: text ? text : 'Something went wrong!',
        confirmButtonColor: '#3085d6',
        footer: '<a href="mailto:jim.cosgrave.web@gmail.com">Report an issue</a>'
    });
}

export {
    showErrorAlert
};