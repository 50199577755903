import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { showErrorAlert } from "../../helpers/alertHelper";
import ScoreEntryModel from '../../models/ScoreEntryModel';
import ScoreEntryHole from "./ScoreEntryHole";
import { getAuthHeader } from "../../helpers/authHelper";

interface State {
    teamId: number
}

// Component that shows the score entry
const ScoreEntry = () => {
    //
    // Get param
    //
    let { teamId } = useParams();
    const navigate = useNavigate();
    // const location = useLocation();
    // const state = location.state as State;
    // const teamId = state.teamId;

    const [scoreEntry, setScoreEntry] = useState<ScoreEntryModel>();

    useEffect(() => {
        fetch(`/api/LiveScoring/GetTeamScore?teamId=${encodeURIComponent(teamId!)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    showErrorAlert("You are not authorized to view this page.");
                    navigate('/');
                    return;
                }

                return response.json()
            })
            .then((json) => {
                setScoreEntry(json);
            });
    }, []);

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2">
            <h2>Team #{scoreEntry?.teamNumber} ({scoreEntry?.teamLeaderName})</h2>
            <div className="row ml-1 mr-1">
                {scoreEntry?.holeScores.map((holeScore) => (
                    <ScoreEntryHole
                        key={holeScore.holeId}
                        teamId={parseInt(teamId!)}
                        holeId={holeScore.holeId}
                        holeNumber={holeScore.holeNumber}
                        score={holeScore.score}
                        par={holeScore.par}
                    />
                ))}
            </div>
        </div>
    );
}

export default ScoreEntry;