const validatePhone = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }

    return phoneNumber
        .toLowerCase()
        .match(
            /\b(?:\+?1[. -]?)?(?:\(\d{3}\)|\d{3})[. -]?\d{3}[. -]?\d{4}\b/g
        );
};

export { validatePhone }