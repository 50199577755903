import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    AddressElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { StripeLinkAuthenticationElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";
import CurrentUserModel from "../../models/CurrentUserModel";
import { useCurrentUser } from "../../contexts/UserContext";
import { usePayment } from "../../contexts/PaymentContext";
import PaymentModel from "../../models/PaymentModel";

interface Props {
    owedAmount: number | undefined;
    htmlContent: string;
}

const CheckoutForm = (props: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    //
    // State
    //
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    //
    // Context
    //
    const payment: PaymentModel | undefined = usePayment();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent!.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e: React.FormEvent<any>) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/payment-confirmation`,
                receipt_email: email, // send a receipt to the email provided
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message ?? '');
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
            <div className="mb-2">
                <div>
                    <h3>Amount Owed: <b>${props.owedAmount}</b></h3>
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
            </div>
            <LinkAuthenticationElement
                id="link-authentication-element"
                options={{ defaultValues: { email: payment?.requestedByEmail ?? 'pcosgrave93@gmail.com' }, }}
                onChange={(e: StripeLinkAuthenticationElementChangeEvent) => setEmail(e.value.email)}
            />
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button className="payment-button" disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

export default CheckoutForm;

