import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const EventDeactivated = () => {
    const navigate = useNavigate();

    const handleViewTeamsClick = () => {
        navigate("/teams");
    }

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2 justify-content-center">
            <h4 className="p-2">Live scoring and Leaderboard will be enabled the day of the event.  Examples of these pages can be seen below.</h4>
            <h3 className="pt-3 border-bottom">Live Scoring</h3>
            <span className="d-inline-block pb-3">The "Live Scoring" page will be used to enter your team's scores after each hole you complete.  This will act as your live scorecard.</span>
            <div className="row">
                <div className="col-4">
                    <img src="/images/sample-hole-1.jpg" alt="..." className="img-fluid"></img>
                </div>
                <div className="col-4">
                    <img src="/images/sample-hole-2.jpg" alt="..." className="img-fluid"></img>
                </div>
                <div className="col-4">
                    <img src="/images/sample-hole-3.jpg" alt="..." className="img-fluid"></img>
                </div>
            </div>
            <h3 className="pt-5 border-bottom">Leaderboard</h3>
            <span className="d-inline-block pb-3">The "Leaderboard" page will be used to see how all other teams in the event are doing.  This screen tracks all teams' scores, and shows you all scores in the tournament.</span>
            <div className="row">
                <div className="col-4">
                    <img src="/images/sample-leaderboard-1.jpg" alt="..." className="img-fluid"></img>
                </div>
                <div className="col-4">
                    <img src="/images/sample-leaderboard-2.jpg" alt="..." className="img-fluid"></img>
                </div>
                <div className="col-4">
                    <img src="/images/sample-leaderboard-3.jpg" alt="..." className="img-fluid"></img>
                    <p style={{ fontStyle: 'italic', fontSize: '12px' }}>*Search by specific participants*</p>
                </div>
            </div>
            <button className="btn btn-primary w-100 mt-4" onClick={handleViewTeamsClick}>View Teams</button>
        </div>
    );
}

export default EventDeactivated;
