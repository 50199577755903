import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { setAuth } from '../../helpers/authHelper';
import CurrentUserModel from '../../models/CurrentUserModel';
import { useUpdateCurrentUser } from '../../contexts/UserContext';

interface Props { }

interface Validation {
    isInvalid: boolean,
    errorList: string[];
}

const Login = (props: Props) => {
    //
    // Context
    //
    const updateCurrentUser = useUpdateCurrentUser();
    //
    // State
    //
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);

    const defaultValidation: Validation = {
        isInvalid: false,
        errorList: []
    };

    const [validation, setValidation] = useState(defaultValidation);

    const navigate = useNavigate();

    function handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
        const newEmail = e.currentTarget.value;
        setUserName(newEmail);

        validate(newEmail, password);
    }

    async function handlePasswordChange(e: React.KeyboardEvent<HTMLInputElement>) {
        const newPassword = e.currentTarget.value;

        if (e.key.toLowerCase() === 'enter') {
            //await handleLogin();
        } else {
            setPassword(newPassword);
        }

        validate(userName, newPassword);
    }

    function validate(pEmail: string, pPassword: string) {
        let enableButton = true;

        if (!pEmail || pEmail.trim().length === 0) {
            enableButton = false;
        }

        if (!pPassword || pPassword.trim().length === 0) {
            enableButton = false;
        }

        setButtonEnabled(enableButton);
    }

    //
    // Login
    //
    async function handleLogin(event?: React.SyntheticEvent) {
        if (event) {
            event.preventDefault();
        }

        if (userName && userName.trim().length > 0 && password && password.trim().length > 0) {
            setButtonEnabled(false);

            const body = {
                email: userName,
                password: password
            };

            const response = await fetch('/api/auth/Login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (response.status != 200) {
                //
                // Server error message
                //
                const newValidation = { ...defaultValidation };
                newValidation.isInvalid = true;
                newValidation.errorList.push('Invalid login request');
                setValidation(newValidation);
                setButtonEnabled(true);

                return;
            }

            const data = await response.json();

            if (data.success) {
                var user = new CurrentUserModel();
                user.email = data.email;
                user.roles = data.roles;
                user.teamId = data.teamId;
                user.name = data.name;

                if (data.roles.includes('Admin')) {
                    user.isAdmin = true;
                }

                updateCurrentUser(user, 'Login.tsx');

                setAuth(userName, data.jwt, data.validTo);
                navigate("/");

            } else {
                //
                // Failed login
                //
                const newValidation = { ...defaultValidation };
                newValidation.isInvalid = true;
                newValidation.errorList.push(data.message);
                setValidation(newValidation);
                setButtonEnabled(true);
            }
        } else {
            alert('Enter email and password');
        }
    }

    return (
        <form onSubmit={handleLogin} noValidate autoComplete="on" className="m-3">
            <h1>Login</h1>
            <div className="card col-12 col-md-6 col-lg-3">
                <div className="card-body">
                    <section>
                        <div id="validation-summary" className="text-danger"></div>
                        <div className="form-floating my-2">
                            <input
                                className="form-control"
                                name="email"
                                autoComplete="username"
                                aria-required="true"
                                autoFocus
                                onChange={e => handleEmailChange(e)}
                            />
                            <label className="form-label">Email</label>
                        </div>
                        <div className="form-floating my-2">
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                autoComplete="password"
                                aria-required="true"
                                onKeyUp={e => handlePasswordChange(e)}
                            />
                            <label className="form-label">Password</label>
                        </div>
                        <div className="mb-3">
                            <input
                                id="login-submit"
                                type="submit"
                                disabled={!buttonEnabled}
                                className="w-100 btn btn-lg btn-primary"
                                value="Log In"
                            />
                        </div>
                        {validation.isInvalid && <div className="alert alert-danger">
                            <ul>
                                {validation.errorList.map((error, index) => {
                                    return (
                                        <li key={index}>{error}</li>
                                    );
                                })}
                            </ul>
                        </div>}
                        <div>
                            <p>
                                <Link to='/auth/forgot-password' id="forgot-password">Forgot your password?</Link>
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </form>
    );

};

export default Login;