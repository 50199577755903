function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    //const returnValue = month + '/' + day + '/' + year;
    const returnValue = year + '-' + month + '-' + day;

    return returnValue;
}

function getCurrentDateMMDDYYYY() {
    const date = new Date();
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    const returnValue = month + '/' + day + '/' + year;

    return returnValue;
}

function getCurrentDate() {
    const returnValue = getFormattedDate(new Date());
    return returnValue;
}

function getCurrentDayName() {
    return getDayName(new Date());
}

function dateAdd(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);

    const returnValue = getFormattedDate(date);

    return returnValue;
}

function getDayName(date) {
    return date.toLocaleDateString('en-US', { weekday: 'long' });
}

function getCurrentDateAndTime() {
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // Convert hours to 12-hour format
    const timeFormat = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Ensure 12-hour format, handle 0 case

    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = currentDate.getFullYear();

    const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${timeFormat}`;

    return formattedDateTime;
}

export {
    getFormattedDate,
    getCurrentDate,
    dateAdd,
    getDayName,
    getCurrentDayName,
    getCurrentDateMMDDYYYY,
    getCurrentDateAndTime
}