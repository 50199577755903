class AttendeeSignUpRequest {
    attendees: AttendeeRequest[] = [];
    shirts: ShirtRequest[] = [];
}

class AttendeeRequest {
    name: string = '';
    type: string = '';
    registeredByEmailAddress: string = '';
    isPaid: boolean = false;
    createdOn: Date = new Date();
    createdOnFormatted: string = '';
}

class ShirtRequest {
    size: string = '';
    type: string = '';
    name: string = '';
    quantity: number = 0;
    requestedByEmailAddress = '';
    isPaid: boolean = false;
    createdOn: Date = new Date();
    createdOnFormatted: string = '';
}

export default AttendeeSignUpRequest;
export { AttendeeRequest, ShirtRequest }