import { useEffect } from "react";

// Component that shows the receipt
const Receipt = () => {
    useEffect(() => {
        //
        // Page load logic goes here
        //
    }, []);

    return (
        <div>
            <h2>Receipt</h2>
        </div>
    );
}

export default Receipt;