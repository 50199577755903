import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePayment } from "../../contexts/PaymentContext";
import PaymentModel from '../../models/PaymentModel';

const PostPayment = () => {
    //
    // Context
    //
    const navigate = useNavigate();
    //
    // State
    //
    const [isSuccessful, setIsSuccessful] = useState<boolean>();
    const payment: PaymentModel | undefined = usePayment();

    useEffect(() => {
        setIsSuccessful(true);
    }, []);

    const handleViewTeamsClick = () => {
        navigate("/teams");
    }

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2 justify-content-center">
            {isSuccessful && <div className="w-100 rounded align-items-center p-3 text-center mx-auto">
                <div className="bg-success p-3">
                    <h2 className="text-white align-middle">Payment successful!</h2>
                </div>
                <div className="alert alert-info align-middle mt-2">
                    {payment && payment.isTeamRequest && <div>
                        Your team has been registered.  Thank you for joining us for a great day in remembrance of Mark.
                        <div>
                            You will receive an email receipt shortly.  Further details will be sent to you as the event approaches.
                        </div>
                    </div>}
                    {payment && !payment.isTeamRequest && <div>
                        Thank you for joining us for a great day in remembrance of Mark.
                        <div>
                            You will receive an email receipt shortly.
                        </div>
                    </div>}
                </div>
            </div>}
            {!isSuccessful && <div className="bg-danger m-3 w-100 rounded align-items-center p-3 text-center mx-auto">
                <span className="text-white align-middle">Payment failed.  Please reach out to Pat Cosgrave (<a className="text-white" href="mailto:pcosgrave93@gmail.com">pcosgrave93@gmail.com</a>) to correct.</span>
            </div>}
            <div className="w-100 rounded align-items-center text-center mx-auto">
                <img style={{ maxWidth: '300px' }} className="img-fluid" src="/images/2024-skramble-logo-black.jpg" alt="..."></img>
            </div>
            <div className="container mt-2">
                <div className="row">
                    <div className="col-6">
                        <Link to="/" className="btn btn-primary btn-tall w-100 mt-3">
                            <i className="bi bi-house"></i> Home
                        </Link>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-tall w-100 mt-3" onClick={handleViewTeamsClick}>View Teams</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostPayment;
