import { ChangeEvent, useEffect, useState } from 'react';
import { useCurrentUser } from './../../contexts/UserContext';
import { useEvent } from './../../contexts/EventContext';
import Loading from '../shared/Loading';
import { getAuthHeader } from '../../helpers/authHelper';
import { showErrorAlert } from '../../helpers/alertHelper';
import { AttendeeRequest } from '../../models/AttendeeSignUpRequest';

class AttendeeSummary {
    type: string = '';
    count: string = '';
}

const Attendees = () => {
    //
    // Context
    //
    const currentUser = useCurrentUser();
    const event = useEvent();
    //
    // State
    //
    const [attendees, setAttendees] = useState<AttendeeRequest[] | undefined>([]);
    const [visibleAttendees, setVisibleAttendees] = useState<AttendeeRequest[] | undefined>([]);
    const [summary, setSummary] = useState<AttendeeSummary[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(true);
    //
    // Use effect
    //
    useEffect(() => {
        const pageLoad = async () => {
            try {
                //
                // Get attendees from server
                //
                const resp = await fetch("/api/admin/get-attendees", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getAuthHeader(),
                    }
                });

                if (resp.status !== 200) {
                    showErrorAlert();
                    console.error(resp);
                }

                const data = await resp.json();
                setAttendees(data.allAttendees);
                setVisibleAttendees(data.allAttendees);

                //
                // Create summary
                //
                const summary = new Map<string, number>();

                data.allAttendees.forEach((attendee: AttendeeRequest) => {

                    if (summary.has(attendee.type)) {

                        summary.set(attendee.type, summary.get(attendee.type)! + 1);
                    }
                    else {
                        summary.set(attendee.type, 1);
                    }
                });

                const summaryArray: AttendeeSummary[] = [];
                summary.forEach((value, key) => {
                    const summaryItem = new AttendeeSummary();
                    summaryItem.type = key;
                    summaryItem.count = value.toString();
                    summaryArray.push(summaryItem);
                });

                setSummary(summaryArray);



            } catch {
                showErrorAlert();
                return;
            } finally {
                setLoading(false);
            }
        }

        pageLoad();
    }, []);

    //
    // Handle search
    //
    function handleSearch(e: ChangeEvent<HTMLInputElement>): void {

        const search = e.target.value.toLowerCase();

        const filteredAttendees = attendees!.filter((attendee) => {
            if (attendee.name!.toString().toLowerCase().includes(search)) {
                return true;
            }

            if (attendee.registeredByEmailAddress!.toString().toLowerCase().includes(search)) {
                return true;
            }

            return false;
        });

        setVisibleAttendees(filteredAttendees);
    }

    return (
        <div className="container p-3">
            <h2>Attendees</h2>

            {loading && <Loading />}

            {!loading && <>


                {summary && summary.length > 0 && <div className="row">
                    <div className="col-12">
                        <h3>Summary</h3>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary.map((summaryItem, index) => {

                                        return (

                                            <tr key={index}>
                                                <td>{summaryItem.type}</td>
                                                <td>{summaryItem.count}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td style={{ textAlign: 'right' }}><b>Total</b></td>
                                        <td><b>{summary.reduce((acc, summaryItem) => acc + parseInt(summaryItem.count), 0)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}


                <div className="my-3 row ml-3 mr-3">
                    <div className="col-12">
                        <h3>Details</h3>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text search-input-icon py-2">
                                    <i className="bi bi-search"></i>
                                </div>
                            </div>
                            <input
                                className="form-control py-2 border-right-0 border"
                                type="search"
                                placeholder="Search"
                                onChange={(e) => { handleSearch(e) }}
                            />
                        </div>
                    </div>
                </div>

                {visibleAttendees && visibleAttendees.length > 0 && <div className="table-responsive">
                    <table className="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Registered By</th>
                                <th>Is Paid</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visibleAttendees.map((attendee, index) => {

                                return (
                                    <tr key={index}>
                                        <td>{attendee.name}</td>
                                        <td>{attendee.type}</td>
                                        <td>{attendee.registeredByEmailAddress}</td>
                                        <td>{attendee.isPaid ? "Yes" : "No"}</td>
                                        <td>{attendee.createdOnFormatted}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
            </>}
        </div>
    )
}

export default Attendees;