import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuthHeader } from '../helpers/authHelper';
import ReloadPageButton from './shared/ReloadPageButton';
import EventDetailsModel from '../models/EventDetailsModel';
import { useUpdateEvent } from '../contexts/EventContext';
import { useCurrentUser } from '../contexts/UserContext';
import Swal from 'sweetalert2';

const Home = () => {
    //
    // Context
    //
    const updateEvent = useUpdateEvent();
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    //
    // State
    //
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [event, setEvent] = useState<EventDetailsModel>();

    useEffect(() => {
        //
        // Get events from the server
        //
        fetch('/api/Home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((json: EventDetailsModel) => {
                setEvent(json);
                updateEvent(json);
            });
    }, []);

    if (error) {
        return <div className="alert alert-danger">
            An error occurred.  Please reload the page.
            <ReloadPageButton></ReloadPageButton>
        </div>
    }

    //
    // Render the buttons that a full event would see
    //
    const renderEventFullPanel = () => {
        if (currentUser) {
            if (currentUser.teamId) {
                if (event && event.scoringIsLocked) {
                    // Case 1: currentUser exists, has teamId, scoring is locked
                    return (
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="panel">
                                    <p className="text-white mb-1">Welcome Golfer!</p>
                                </div>
                                <div className="panel mt-2">
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <Link to="leaderboard" className="btn btn-success btn-lg px-4 me-sm-3">Leaderboard</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    // Case 3: currentUser exists, has teamId, scoring isn't locked
                    return (
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="panel">
                                    <p className="text-white mb-1">Welcome Golfer!</p>
                                </div>
                                <div className="panel">
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <Link to="live-scoring" className="btn btn-success btn-lg px-4 me-sm-3">Live Scoring</Link>
                                    </div>
                                </div>
                            </div>
                        </div >
                    );
                }
            }
        }
        // Case 2: currentUser doesn't exist
        return (
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="panel">
                        <p className="text-white mb-1">Event is currently full. View Leaderboard?</p>
                    </div>
                    <div className="panel">
                        <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                            <Link to="leaderboard" className="btn btn-success btn-lg px-4 me-sm-3">Leaderboard</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    //
    // Render the sign up button
    //
    const renderSignUpButton = () => {
        //
        // If the event is full, disable the button
        //
        if (event && event.isFull) {
            return (<div className="panel">
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                    <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>Event is full</div>
                </div>
            </div>);
        }

        //
        // If the event is not full, render the button
        //
        if (event && event.registrationEnabled) {
            return (<div className="panel">
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                    <Link to="sign-up" className="btn btn-primary btn-lg px-4 me-sm-3">Sign Up To Golf</Link>
                </div>
            </div>);
        }

        //
        // If registration is not enabled, render a message
        //
        if (!event || !event.registrationEnabled) {
            return (
                <div className="panel">
                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                        {/* <button className="btn btn-primary btn-lg px-4 me-sm-3" disabled>Registration is Currently Disabled</button> */}
                        <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                            Registration has not started.  Please check back later.
                        </div>
                    </div>
                </div>
            )
        }
    }

    //
    // Render the sign up button
    //
    const renderDinnerButton = () => {
        if (!event) {
            return <></>;
        }

        if (event.attendeesAreFull) {
            return <div className="panel">
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                    <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                        This event is full.
                    </div>
                </div>
            </div>
        }

        //
        // If registration is not enabled, render a message
        //
        return <div className="panel">
            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                <Link to="dinner-sign-up" className="btn btn-primary btn-lg px-4 me-sm-3">Attend Dinner/Request Shirts</Link>
            </div>
        </div>;
    }

    const renderDonateButton = () => {
        return <div className="panel">
            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                <a href="https://donate.stripe.com/fZe16zeev9UNcx2288" target="_blank" className="btn btn-primary btn-lg px-4 me-sm-3">Donate</a>
            </div>
        </div>;
    }

    const handleSignUpClick = () => {
        navigate("/sign-up");
    }

    const openShirtModal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        Swal.fire({
            imageUrl: '/images/2024-skramble-shirt.jpg',
            imageAlt: 'Shirt',
            showCloseButton: false,
            showConfirmButton: false
        });
    }

    return (
        <>
            <header className="bg-dark py-1">
                <div className="container px-5">
                    <div className="row gx-5 align-items-center justify-content-center">
                        <div className="col-lg-8 col-xl-7 col-xxl-6">
                            <div className="my-5 text-center text-xl-start">
                                <h1 className="display-5 fw-bolder text-white mb-2">The sKRAMble</h1>
                                <div className="col-lg-12 d-xl-none text-center">
                                    <img className="img-fluid rounded-3 my-3" src="/images/mark.jpg" alt="..."></img>
                                </div>
                                <p className="lead fw-normal text-white-50 mb-4">Join us for the second annual golf outing honoring the life of Mark Pulice Jr. A portion of all proceeds will go to the Mark Pulice Jr. Memorial Scholarship Fund, supported by Tinley Park High School.</p>
                                {event && !event.eventIsFinalized && renderEventFullPanel()}
                                {event && event.registrationEnabled && !event.eventIsFinalized && <>
                                    {!currentUser && !event.isFull && <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="panel">
                                                <p className="text-white mb-1">Interested in playing?</p>
                                            </div>
                                            {renderSignUpButton()}
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="panel">
                                                <p className="text-white mb-1">Want to attend dinner or request shirts?</p> <a className="pb-3 d-inline-block" href="#" onClick={openShirtModal}>Check out this year's shirt!</a>
                                            </div>
                                            {renderDinnerButton()}
                                        </div>
                                    </div>
                                </>}
                                {event && !event.registrationEnabled && event.eventIsFinalized && <div className="panel">
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                                            Registration is currently closed.  Please check back later.
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <p className="text-white mb-1">Want to donate?</p>
                                        </div>
                                        {renderDonateButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                            <img className="img-fluid rounded-3 my-5" src="/images/mark.jpg" alt="..."></img>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container pt-5" id="featured-3">
                <h1 className="pb-2 border-bottom">Event Details</h1>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div className="feature col">
                        <h2>When and Where?</h2>
                        <p>July 20th, 2024
                            <br />
                            Lincoln Oaks - Crete, IL
                        </p>
                        <p>395 E Richton Rd, Crete IL 60417</p>
                        <a href="https://www.lincolnoaksgolfcourse.com/" className="icon-link" target="_blank">
                            https://www.lincolnoaksgolfcourse.com/
                        </a>
                    </div>
                    <div className="feature col">
                        <h2>What is Included?</h2>
                        <p>
                            18 holes of golf (cart included); Range balls; Lunch; Dinner; 3 drink tickets and T-Shirt.
                            Shotgun start will begin at 1:00pm. Prizes will also be raffled off during dinner.
                        </p>
                        <p>Cash bar available at the course throughout the event.</p>
                    </div>
                    <div className="feature col">
                        <h2>Questions?</h2>
                        <p>If you have any questions about the event, are interested in providing raffle donations, or need help signing up, please contact Patrick & Jianna Cosgrave.</p>
                        <div>
                            Phone: <a href="tel:+7083346191" className="icon-link">
                                (708) 334-6191
                            </a>
                        </div>
                        <div>
                            Email: <a href="mailto:theskrambleouting@gmail.com" className="icon-link">theskrambleouting@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-3" id="featured-3">
                <h1 className="pb-2 border-bottom">Photo Gallery</h1>
                <p>Check out photos from the 2023 Event at Ruffled Feathers <a href="https://theluchenes.client.photos/gallery/the-mark-pulice-annual-skramble" target="_blank">here</a>!</p>
                <p>All photography done by Francesca Luchene</p>
                <div className="row g-4 row-cols-1 row-cols-lg-2">
                    <div className="feature col">
                        <img className="img-fluid rounded-3" src="/images/2023-skramble-1.jpg" alt="..."></img>
                    </div>
                    <div className="feature col">
                        <img className="img-fluid rounded-3" src="/images/2023-skramble-74.jpg" alt="..."></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;