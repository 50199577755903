import { Link } from 'react-router-dom';
import { useCurrentUser } from "../../contexts/UserContext";

interface Props {
    showLink: boolean;
    linkText: string;
    linkUrl: string;
}

const CurrentTeam = (props: Props) => {
    //
    // Context
    //
    const user = useCurrentUser();

    return (
        <div className="ctx-menu text-center narrow-page">
            {props.showLink && <Link to={props.linkUrl}
                className="btn btn-warning btn-lg w-100 mb-1">{props.linkText}
            </Link>}
            <div className="ctx-menu-bottom">
                Team: {user?.name}
            </div>
        </div>
    );
}

export default CurrentTeam;