class PlayerSignUpModel {
    playerNumber: number;
    firstName?: string = '';
    lastName?: string = '';
    email?: string = '';
    phoneNumber?: string = '';
    shirtSize: string = '';
    isTeamLeader: boolean = false;
    isValid: boolean = false;
    emailIsValid: boolean = false;
    phoneIsValid: boolean = false;

    constructor(playerNumber: number, isTeamLeader: boolean, shirtSize: string = '') {
        this.playerNumber = playerNumber;
        this.isTeamLeader = isTeamLeader;
        this.shirtSize = shirtSize;
    }
}

export default PlayerSignUpModel;