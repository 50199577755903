import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUpdateCurrentUser } from '../../contexts/UserContext';
import { showErrorAlert } from '../../helpers/alertHelper';
import CurrentUserModel from '../../models/CurrentUserModel';
import PlayerSignUpModel from '../../models/PlayerSignUpModel';
import { usePayment, useUpdatePayment } from '../../contexts/PaymentContext';
import Loading from '../shared/Loading';
import PlayerSignUp from './PlayerSignUp';
import LoginResponseModel from '../../models/LoginResponseModel';
import EventDetailsModel from '../../models/EventDetailsModel';
import PaymentModel from '../../models/PaymentModel';

const SignUp = () => {
    const navigate = useNavigate();
    //
    // State
    //
    const [players, setPlayers] = useState<PlayerSignUpModel[]>([]);
    const [isInvalid, setIsInvalid] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [event, setEvent] = useState<EventDetailsModel>();
    //
    // Context
    //
    const updateCurrentUser = useUpdateCurrentUser();
    const payment = usePayment();
    const updatePayment = useUpdatePayment();
    //
    // Constants
    //
    const PLAYER_COUNT = 4;

    useEffect(() => {
        //
        // Get events from the server
        //
        fetch('/api/Home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((json: EventDetailsModel) => {
                setLoading(false);
                setEvent(json);

                //
                // If the event is full, redirect to the home page
                //
                if (json.isFull) {
                    navigate('/');
                }
            });

        const newPlayerList = new Array<PlayerSignUpModel>();
        let isTeamLeader = true;

        /*
         * Start at 1 because player number is something we want to display on the screen
         */
        for (let i = 1; i < (PLAYER_COUNT + 1); i++) {
            let newPlayer = new PlayerSignUpModel(i, isTeamLeader);
            newPlayerList.push(newPlayer);

            //
            // First player becomes team leader - All others are not.
            //
            isTeamLeader = false;
        }

        setPlayers(newPlayerList);
        //setPlayers(getTestPlayers())
    }, []);

    const getTestPlayers = () => {
        return []
    }

    useEffect(() => {
        let isInvalid = false;

        players.forEach((p) => {
            if (!p.isValid) {
                isInvalid = true;
            }
        });

        setIsInvalid(isInvalid);
    }, [players])

    //
    // Child component player has been updated
    //
    const playerUpdated = (updatedPlayer?: PlayerSignUpModel) => {
        if (!updatedPlayer) {
            return;
        }

        const playerIndex = players.map((p: PlayerSignUpModel) => { return p.playerNumber }).indexOf(updatedPlayer.playerNumber);
        const updatedPlayers = players.slice();
        updatedPlayers[playerIndex] = updatedPlayer;

        setPlayers(updatedPlayers);
    }

    //
    // EVENT - Click
    // Handle sign up on button click
    //
    const handleSignUpClick = async () => {
        setSaving(true);

        const body = {
            players
        };

        const response = await fetch('/api/SignUp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (response.status != 200) {
            //
            // Server error message
            //
            setSaving(false);
            showErrorAlert();
            return;
        }

        const data: LoginResponseModel = await response.json();

        if (data.success) {
            //
            // Update payment context
            //
            const firstPlayer = players[0];
            const newPayment = new PaymentModel();
            newPayment.isTeamRequest = true;
            newPayment.requestedByEmail = firstPlayer.email;
            newPayment.requestedByFirstName = firstPlayer.firstName;
            newPayment.requestedByLastName = firstPlayer.lastName;

            updatePayment(newPayment);

            navigate("/payment");
        } else {
            setSaving(false);
            showErrorAlert();
            return;
        }
    }

    const renderPlayerSignUp = (): React.ReactNode => {
        return (
            <>
                <div className="row">
                    {players.slice(0, 2).map((player) => {
                        return (
                            <PlayerSignUp
                                key={player.playerNumber}
                                playerNumber={player.playerNumber}
                                isTeamLeader={player.isTeamLeader}
                                onUpdate={playerUpdated}

                                firstName={player.firstName}
                                lastName={player.lastName}
                                email={player.email}
                                phoneNumber={player.phoneNumber}
                                shirtSize={player.shirtSize}
                            ></PlayerSignUp>
                        );
                    })}
                </div>
                <div className="row">
                    {players.slice(2, 4).map((player) => {
                        return (
                            <PlayerSignUp
                                key={player.playerNumber}
                                playerNumber={player.playerNumber}
                                isTeamLeader={player.isTeamLeader}
                                onUpdate={playerUpdated}

                                firstName={player.firstName}
                                lastName={player.lastName}
                                email={player.email}
                                phoneNumber={player.phoneNumber}
                                shirtSize={player.shirtSize}

                            ></PlayerSignUp>
                        );
                    })}
                </div>
            </>
        );
    }

    const renderButtons = (): React.ReactNode => {
        return (
            <div className="mt-2">
                <button
                    className="btn btn-primary btn-tall w-100"
                    onClick={handleSignUpClick}
                    disabled={isInvalid}
                >Sign up
                </button>
            </div>
        );
    }

    const renderSignUpStyle = saving ? { display: 'none' } : {};

    if (loading) {
        return <Loading message='Loading Event Details...' />;
    }

    if (event && !event.registrationEnabled) {
        return (
            <div className="py-lg-3 py-md-2 py-sm-2 px-2">
                <div className="m-2">
                    <h3>Registration is currently closed.</h3>
                </div>
            </div>
        )
    }

    if (saving) {
        return <Loading message='Signing You Up...' />;
    }

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2">
            <div className="m-2">
                <h1>Sign Up</h1>
                {event && <p
                    className="alert alert-info"
                >
                    Sign up your team of 4 players.  Payment for all 4 players will be collected on the next screen.  ${event.eventFee} total will be collected - ${event.eventFee / 4} per player.  Visit the <Link to="/">Home Page</Link> for details on everything this payment covers.
                </p>}
            </div>
            <div style={renderSignUpStyle} className="m-2">
                {renderPlayerSignUp()}
                {renderButtons()}
            </div>
        </div>
    );
}

export default SignUp;