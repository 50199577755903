import { useEffect, useState } from "react";
import { showErrorAlert } from "../../helpers/alertHelper";
import { getAuthHeader } from "../../helpers/authHelper";

interface Props {
    teamId: number;
    holeId: number;
    holeNumber: number;
    score: number;
    par: number;
}

const DEFAULT_SCORE = -1;

const ScoreEntryHole = (props: Props) => {
    //
    // State
    //
    const [score, setScore] = useState<number>(DEFAULT_SCORE);

    //
    // Update the score on the server
    //
    useEffect(() => {
        //
        // Set a timeout to prevent the server from being spammed
        //
        const timer = setTimeout(async () => {
            //
            // Dont post NULL or default scores
            //
            if (!score || score == DEFAULT_SCORE) {
                return;
            }

            const body = {
                teamId: props.teamId,
                holeId: props.holeId,
                score: score
            };

            const response = await fetch('/api/LiveScoring/UpdateTeamHoleScore', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getAuthHeader(),
                },
                body: JSON.stringify(body)
            });

            if (response.status != 200) {
                //
                // Server error message
                //
                showErrorAlert();
                return;
            }
        }, 500);

        //
        // Clear the timeout of the function is called again
        //
        return () => clearTimeout(timer);
    }, [score]);

    //
    // Handle the score change
    //
    const handleScoreChange = async (e: any) => {
        const inputValue = (e.target as HTMLInputElement).value;

        setScore(parseInt(inputValue));
    }

    return (
        <div key={props.holeId} className="col-lg-2 col-md-6 col-sm-12">
            <div className="card mb-3">
                <div className="card-header">
                    <div className="flex space-between">
                        <div>
                            <div className="h5 card-title" style={{ marginBottom: '0' }}>
                                Hole #{props.holeNumber}
                                <span style={{ paddingLeft: '.25rem' }} className="h6">(Par {props.par})</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-floating my-2">
                        <input
                            className="form-control"
                            name="score"
                            type="number"
                            defaultValue={props.score}
                            onChange={handleScoreChange}
                        />
                        <label className="form-label">Score</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScoreEntryHole;