import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import LiveScoringModel from '../../models/LiveScoringModel';
import LiveScoringHoleModel from '../../models/LiveScoringHoleModel';
import { getAuthHeader } from '../../helpers/authHelper';
import { useCurrentUser } from '../../contexts/UserContext';
import { showErrorAlert } from '../../helpers/alertHelper';
import CurrentTeam from '../shared/CurrentTeam';
import Loading from '../shared/Loading';
import UpdateTeamHoleScoreResponseModel from '../../models/UpdateTeamHoleScoreResponseModel';

const LiveScoring = () => {
    //
    // Context
    //
    const user = useCurrentUser();
    //
    // State
    //
    const [liveScoring, setLiveScoring] = useState<LiveScoringModel>();
    const [currentHoleNumber, setCurrentHoleNumber] = useState<number>(1);
    const [currentHole, setCurrentHole] = useState<LiveScoringHoleModel>();
    const [showConfirmScore, setShowConfirmScore] = useState<boolean>(false);
    const [finalScore, setFinalScore] = useState<number>(0);
    const [finalScoreConfirmed, setFinalScoreConfirmed] = useState<boolean>(false);
    const [scoringIsLocked, setScoringIsLocked] = useState<boolean>(false);
    //
    // Variables
    //
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // Get teams from the server
        fetch("/api/LiveScoring/GetLiveScoring", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            }
        })
            .then((response) => response.json())
            .then((json: LiveScoringModel) => {
                setLiveScoring(json);
                setCurrentHoleNumber(json.currentHole);
                setCurrentHole(json.courseHoles.find(h => h.holeNumber == json.currentHole));

                /*
                Determine if the user should be prompted to confirm the score
                */
                setShowConfirmScore(json.showConfirmScore);
                setFinalScore(json.finalScore);
                setFinalScoreConfirmed(json.finalScoreConfirmed);
                setScoringIsLocked(json.scoringIsLocked);
            });
    }, []);

    //
    // Monitor changes to current hole number
    //
    useEffect(() => {
        if (currentHoleNumber && currentHoleNumber > 0) {
            if (liveScoring) {
                const hole = liveScoring.courseHoles.find(h => h.holeNumber == currentHoleNumber);

                if (hole) {
                    setCurrentHole(hole);
                }
            }
        }
    }, [currentHoleNumber]);

    //
    // Post changes to server
    //
    useEffect(() => {
        const timer = setTimeout(async () => {
            if (currentHole && user) {
                //postTeamHoleScore();
            }
        }, 500);

        //
        // Clear the timeout of the function is called again
        //
        return () => clearTimeout(timer);
    }, [currentHole]);

    const handleNextClick = async () => {
        var holeNumber = 1;

        if (currentHoleNumber == 18) {
            setCurrentHoleNumber(1);
        } else {
            holeNumber = currentHoleNumber + 1;
            setCurrentHoleNumber(currentHoleNumber + 1);
        }

        //
        // Save score if the user moves to the next hole
        //
        await postTeamHoleScore(holeNumber);
    }

    const handleBackClick = async () => {
        var holeNumber = 18;

        if (currentHoleNumber == 1) {
            setCurrentHoleNumber(18);
        } else {
            holeNumber = currentHoleNumber - 1;
            setCurrentHoleNumber(currentHoleNumber - 1);
        }

        //
        // Save score if the user moves to the next hole
        //
        await postTeamHoleScore(holeNumber);
    }

    //
    // Score goes down
    //
    const handleScoreDown = async () => {
        if (currentHole && currentHole.teamScore > 1) {
            currentHole.teamScore -= 1;
            setCurrentHole({ ...currentHole });

            var holeIndex = liveScoring!.courseHoles.findIndex(h => h.holeNumber == currentHoleNumber);
            liveScoring!.courseHoles[holeIndex] = currentHole;
            setLiveScoring({ ...liveScoring! });

            clearTimeout(debounceTimer!);

            setDebounceTimer(setTimeout(async () => {
                await postTeamHoleScore(currentHole.holeNumber);
            }, 500));
        }
    }

    //
    // Score goes up
    //
    const handleScoreUp = async () => {
        if (currentHole) {
            currentHole.teamScore += 1;
            setCurrentHole({ ...currentHole });

            var holeIndex = liveScoring!.courseHoles.findIndex(h => h.holeNumber == currentHoleNumber);
            liveScoring!.courseHoles[holeIndex] = currentHole;
            setLiveScoring({ ...liveScoring! });

            clearTimeout(debounceTimer!);

            setDebounceTimer(setTimeout(async () => {
                await postTeamHoleScore(currentHole.holeNumber);
            }, 500));
        }
    }

    //
    // Post the team score to the server
    //
    const postTeamHoleScore = async (lastHoleViewed: number) => {
        const body = {
            teamId: user?.teamId,
            holeId: currentHole?.holeId,
            score: currentHole?.teamScore,
            lastHoleViewed: lastHoleViewed
        };

        const response = await fetch('/api/LiveScoring/UpdateTeamHoleScore', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify(body)
        });

        if (response.status != 200) {
            //
            // Server error message
            //
            showErrorAlert();
            return;
        }

        /*
        Determine if the user should be prompted to confirm the score
        */
        const json: UpdateTeamHoleScoreResponseModel = await response.json();
        setShowConfirmScore(json.showConfirmScore);
        setFinalScore(json.finalScore);
        setFinalScoreConfirmed(json.finalScoreConfirmed);
        setScoringIsLocked(json.scoringIsLocked);
    }

    //
    // User has confirmed the score
    //
    const handleConfirmScore = async () => {
        const body = {
            teamId: user?.teamId
        };

        const response = await fetch('/api/LiveScoring/ConfirmScore', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify(body)
        });

        if (response.status != 200) {
            //
            // Server error message
            //
            showErrorAlert();
            return;
        } else {
            setFinalScoreConfirmed(true);
        }
    }

    if (!currentHole) {
        return (
            <div className="m-2">
                <Loading />
            </div>
        );
    }

    return (
        <div className="ctx-menu-page narrow-page">
            <div className="outing-title flex space-between">
                <div className="back" onClick={handleBackClick}>
                    <i className="bi bi-rewind-fill clickable"></i>
                </div>
                <div>
                    Hole {currentHoleNumber}
                </div>
                <div className="forward" onClick={handleNextClick}>
                    <i className="bi bi-fast-forward-fill clickable"></i>
                </div>
            </div>
            {<div className="space-filler my-2" style={{ textAlign: "center" }}>
                <img src={currentHole.imageUrl} style={{ width: "275px", height: "180px" }} />
            </div>}
            <div className="par flex space-evenly py-2">
                <div>Par {currentHole.par} ({currentHole.yardage} yd)</div>
                <div>Hdcp {currentHole.handicap}</div>
            </div>
            {!scoringIsLocked && <div className="p-2">
                <div className="flex space-between align-items-center">
                    <div
                        style={{ fontSize: '42px' }}
                        onClick={handleScoreDown}
                    >
                        <i className="bi bi-dash-circle clickable"></i>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: '52px' }}>{currentHole.teamScore}</div>
                        <div style={{ fontStyle: 'italic' }}>Set Your Team Score</div>
                    </div>
                    <div
                        style={{ fontSize: '42px' }}
                        onClick={handleScoreUp}
                    >
                        <i className="bi bi-plus-circle clickable"></i>
                    </div>
                </div>
            </div>}
            {scoringIsLocked && <div className="m-2 alert alert-danger text-center">
                Scoring is locked.  Please view the leaderboard to check your score.
            </div>}
            {showConfirmScore && !finalScoreConfirmed && <div
                className="m-2 alert alert-info flex space-evenly align-items-center"
                style={{ "borderRadius": "0" }}
            >
                <div>
                    Confirm final score ({finalScore})?
                </div>
                <div>
                    <button className="btn btn-primary" onClick={handleConfirmScore}>Yes</button>
                </div>
            </div>}
            {finalScoreConfirmed && <div
                className="m-2 alert alert-info flex space-evenly align-items-center"
            >
                Final score confirmed.  {!scoringIsLocked ? 'You can still make changes until the round is closed.' : ''}
            </div>}
            <CurrentTeam showLink={true} linkText="View Leaderboard" linkUrl="/leaderboard" />
        </div>
    );
}

export default LiveScoring;