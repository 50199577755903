import { useEffect, useState } from "react";
import { getAuthHeader } from "../../helpers/authHelper";
import EventModel from "../../models/EventModel";
import { useCurrentUser } from "../../contexts/UserContext";
import Loading from "../shared/Loading";

const EventList = () => {
    //
    // Context
    //
    const user = useCurrentUser();
    //
    // State
    //
    const [events, setEvents] = useState<EventModel[]>();

    useEffect(() => {
        //
        // Get events from the server
        //
        fetch('/api/Golf/GetEventList', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            }
        })
            .then((response) => response.json())
            .then((json: EventModel[]) => {
                setEvents(json);
            });
    }, []);

    //
    // Show loading message
    //
    if (!events) {
        return <Loading message="Loading events..." />
    }

    //
    // Toggle registration enabled
    //
    const handleRegistrationEnabled = (eventId: number) => {
        fetch('/api/Admin/RegistrationEnabled', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify({
                eventId: eventId,
            })
        })
            .then(() => {
                const event = events.find(e => e.eventId == eventId);

                if (event) {
                    event.registrationEnabled = !event?.registrationEnabled;
                    setEvents([...events]);
                }

                alert('Registration updated.');
            });
    };

    //
    // Lock scoring for an event
    //
    const handleLockScoring = (eventId: number) => {
        fetch('/api/Admin/LockScoring', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify({
                eventId: eventId,
            })
        })
            .then(() => {
                const event = events.find(e => e.eventId == eventId);

                if (event) {
                    event.scoringIsLocked = !event?.scoringIsLocked;
                    setEvents([...events]);
                }

                alert('Scoring updated.');
            });
    };

    //
    // Finalize an event
    //
    const handleFinalizeEvent = (eventId: number) => {
        fetch('/api/Admin/FinalizeEvent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify({
                eventId: eventId,
            })
        })
            .then(() => {
                const event = events.find(e => e.eventId == eventId);

                if (event) {
                    event.eventIsFinalized = !event?.eventIsFinalized;
                    setEvents([...events]);
                }

                alert('Event status updated.');
            });
    }

    //
    // Render
    //
    return (
        <div className="py-2 px-2">
            <h1>Events</h1>
            <div className="row">
                {events && events.map((event) => {
                    return (
                        <div key={event.eventId} className="col-md-6 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="flex space-between">
                                        <div>
                                            <div className="h5 card-title" style={{ marginBottom: '0' }}>
                                                {event.eventName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {event.eventIsFinalized && <div className="alert alert-success">Finalized</div>}
                                    {event.scoringIsLocked && <div className="alert alert-primary">Scoring Is Locked</div>}
                                    {event.registrationEnabled && <div className="alert alert-dark">Registration Enabled</div>}
                                    <div>
                                        <b>Event Date</b>: {new Date(event.eventDate).toLocaleDateString()}
                                    </div>
                                    <div>
                                        <b>Course</b>: {event.course.courseName}
                                    </div>
                                    <div>
                                        <b>Address</b>: {event.course.addressStreet}, {event.course.addressCity}, {event.course.addressState} {event.course.addressZipCode}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="flex space-between">
                                        <button className="btn btn-dark" onClick={() => handleRegistrationEnabled(event.eventId)}>{event.registrationEnabled ? 'Disable Registration' : 'Enable Registration'}</button>
                                        <button className="btn btn-primary" onClick={() => handleLockScoring(event.eventId)}>{event.scoringIsLocked ? 'Unlock Scoring' : 'Lock Scoring'}</button>
                                        <button className="btn btn-success" onClick={() => handleFinalizeEvent(event.eventId)}>{event.eventIsFinalized ? 'Re-open Event' : 'Finalize Event'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default EventList;