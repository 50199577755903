class CurrentUserModel {
    firstName?: string = '';
    lastName?: string = '';
    name?: string = '';
    email: string = '';
    roles: string[] = [];
    teamId: number = 0;
    isAdmin: boolean = false;
    teamHasPaid: boolean = false;
}

export default CurrentUserModel;