import React, { useState, useEffect } from "react";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { usePayment, useUpdatePayment } from "../../contexts/PaymentContext";
import GetTeamPaymentInfoResponseModel from "../../models/GetTeamPaymentInfoResponse";
import Loading from "../shared/Loading";
import { useNavigate } from "react-router-dom";
import { showErrorAlert } from "../../helpers/alertHelper";
import PaymentModel from "../../models/PaymentModel";

interface Props { }

// Make sure to call loadStripe outside of a component�s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
//const stripePromise = loadStripe("pk_test_51MVzj1GUyxud8LVVhn3tyit0vAJZ4HpVKAQxWDruNQLneVti6DJaul9nzV0Cdjv3RQkh0P78xe43LUtbGT7o6IzI00Lz0gGUWN");
const stripePromise = loadStripe("pk_live_51MVzj1GUyxud8LVVhEX0SafUUvxJWVmdSVvAh9STHK9tZRftFtzkiuRI6PkehIrx6QzchDz877DewH0RinE72Qb300uaySN4Xo");

const Payment = (props: Props) => {
    //
    // State
    //
    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [teamPaymentInfo, setTeamPaymentInfo] = useState<GetTeamPaymentInfoResponseModel>();
    const [owedAmount, setOwedAmount] = useState<number | undefined>();
    const [checkoutMessage, setCheckoutMessage] = useState<string>('');
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    //
    // Context
    //
    const payment: PaymentModel | undefined = usePayment();
    //
    // Other
    //
    const navigate = useNavigate();

    useEffect(() => {
        async function pageLoadAttendeeRequest() {
            try {
                const body = {
                    numberOfAttendees: payment?.attendeeRequest?.attendees.length,
                    numberOfShirts: payment?.attendeeRequest?.shirts.reduce((acc, size) => acc + size.quantity, 0)
                };

                const response = await fetch('/api/payment/stripe/attendee-payment-request', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });

                if (response.status !== 200) {
                    //
                    // Server error message
                    //
                    showErrorAlert();
                    return;
                }

                const json = await response.json();
                setOwedAmount(json.amountOwed);

                let lis = '';

                json.fees.forEach((fee: any) => {
                    if (fee.totalAmount > 0) {
                        lis += '<li>' + fee.feeName + ' - $' + fee.totalAmount + ' ($' + fee.individualAmount + ' each)</li>';
                    }
                });

                setCheckoutMessage('<ul>' + lis + '</ul>');

                //
                // Create PaymentIntent as soon as the page loads
                //
                fetch("/api/payment/stripe/create", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        amountOwed: Math.ceil(json.amountOwed),
                        user: {
                            email: payment?.requestedByEmail ?? 'pcosgrave93@gmail.com',
                            firstname: 'Patrick',
                            lastname: 'Cosgrave',
                            paymentTrackingId: payment?.paymentTrackingId,
                            type: 'Attendee/Shirt'
                        }
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setClientSecret(data.clientSecret)
                        setLoading(false);
                    });
            } catch {
                showErrorAlert();
            } finally {
                setLoading(false);
            }
        }

        if (payment && !isRedirecting) {
            if (payment.isTeamRequest) {
                //
                // Get the current team's payment info
                //
                fetch('/api/payment/stripe/getTeamPaymentInfo/' + payment.requestedByEmail)
                    .then((response) => response.json())
                    .then((json: GetTeamPaymentInfoResponseModel) => {
                        //
                        // Stop loading icon
                        //

                        setTeamPaymentInfo(json);
                        setOwedAmount(json.owedAmount);
                        setCheckoutMessage(`<b>Payment below is for your team in full - $${json.owedAmount / 4} per player.</b>`);

                        //
                        // If the team has paid in full, redirect to the dashboard
                        //
                        if (json.hasPaidInFull) {
                            setLoading(false);
                            //const updatedUser = { ...currentUser };
                            //updatedUser.teamHasPaid = true;
                            //updateCurrentUser(updatedUser);

                            setIsRedirecting(true);

                            setTimeout(() => {
                                navigate('/');
                            }, 3000);

                            return;
                        }

                        //
                        // Create PaymentIntent as soon as the page loads
                        //
                        fetch("/api/payment/stripe/create", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                amountOwed: Math.ceil(json.owedAmount),
                                user: {
                                    email: payment?.requestedByEmail ?? 'pcosgrave93@gmail.com',
                                    firstname: payment?.requestedByFirstName ?? 'Patrick',
                                    lastname: payment?.requestedByLastName ?? 'Cosgrave',
                                    paymentTrackingId: payment.paymentTrackingId,
                                    type: 'Golf'
                                }
                            }),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                setClientSecret(data.clientSecret)
                                setLoading(false);
                            });
                    })
                    .catch((e) => {
                        setError(true);
                    });
            } else if (payment.isAttendeeRequest) {
                pageLoadAttendeeRequest();
            } else {
                //
                // Unknown payment type.  Go back to home page.
                //
                navigate('/');
            }
        }
    }, [payment]);

    const appearance: Appearance = {
        theme: 'stripe'
    };

    const options: StripeElementsOptions = {
        clientSecret,
        appearance,
    };

    if (error) {
        return <div className="alert alert-danger">An error occurred</div>
    }

    if (loading) {
        return <Loading message="Loading payment information..." />
    }

    if (teamPaymentInfo?.hasPaidInFull) {
        return <Loading message="You have already paid in full. Redirecting to dashboard..." />
    }

    return (
        <div className="Payment mt-4 mb-4 payment-container">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm owedAmount={owedAmount} htmlContent={checkoutMessage} />
                </Elements>
            )}
        </div>
    );
}

export default Payment;