const APP_NAME = 'sKRAMble';
const LOCAL_STORAGE_USER = 'LOCAL_STORAGE_USER';
const LOCAL_STORAGE_EVENT = 'LOCAL_STORAGE_EVENT';
const LOCAL_STORAGE_PAYMENT = 'LOCAL_STORAGE_PAYMENT';

export {
    APP_NAME,
    LOCAL_STORAGE_USER,
    LOCAL_STORAGE_EVENT,
    LOCAL_STORAGE_PAYMENT
}