import { LOCAL_STORAGE_EVENT, LOCAL_STORAGE_PAYMENT, LOCAL_STORAGE_USER } from "./constants";

const USERNAME = 'username';
const TOKEN = 'token';
const EXPIRES = 'expires';

function isAuthenticated() {
    let isAuthenticated = false;
    localStorage.getItem(TOKEN) ? isAuthenticated = true : isAuthenticated = false;

    return isAuthenticated;
}

function setAuth(username, jwt, expires) {
    localStorage.setItem(USERNAME, username);
    localStorage.setItem(TOKEN, jwt);
    localStorage.setItem(EXPIRES, expires);

    window.dispatchEvent(new Event('storage'));
}

function removeAuth() {
    localStorage.removeItem(LOCAL_STORAGE_USER);
    localStorage.removeItem(LOCAL_STORAGE_PAYMENT);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRES);
    window.dispatchEvent(new Event('storage'));
}

function getAuthHeader() {
    if (tokenIsValid()) {
        const token = localStorage.getItem(TOKEN);
        return 'Bearer ' + token;
    }

    removeAuth();
    window.location.href = "/login"; 
    return '';
}

function tokenIsValid() {
    var expires = localStorage.getItem(EXPIRES);

    if (Date.parse(expires) < (new Date())) {
        return false;
    }

    return true;
}

function getUserName() {
    var userName = 'Unknown';

    var ls = localStorage.getItem(USERNAME);

    if (ls) {
        userName = ls;
    }

    return userName;
}

export {
    isAuthenticated,
    setAuth,
    removeAuth,
    getAuthHeader,
    getUserName
};