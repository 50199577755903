import React, { useEffect, useState } from 'react';
import { validateEmail } from '../../helpers/emailHelper';
import { validatePhone } from '../../helpers/phoneHelper';
import PlayerSignUpModel from '../../models/PlayerSignUpModel';

interface Props {
    playerNumber: number,
    isTeamLeader: boolean,
    onUpdate: (player: PlayerSignUpModel) => void,

    firstName?: string | undefined,
    lastName?: string | undefined,
    phoneNumber?: string | undefined,
    email?: string | undefined,
    shirtSize?: string | undefined,
}

const PlayerSignUp = (props: Props) => {
    //
    // State
    //
    const [player, setPlayer] = useState<PlayerSignUpModel>({
        playerNumber: props.playerNumber,
        isTeamLeader: props.isTeamLeader,
        emailIsValid: !props.isTeamLeader,
        phoneIsValid: !props.isTeamLeader,
        isValid: false,

        firstName: props.firstName ?? '',
        lastName: props.lastName ?? '',
        phoneNumber: props.phoneNumber ?? '',
        email: props.email ?? '',
        shirtSize: props.shirtSize ?? ''
    });
    const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
    const [lastNameValid, setLastNameValid] = useState<boolean>(false);
    const [shirtValid, setShirtValid] = useState<boolean>(false);
    const [emailValid, setEmailValid] = useState<boolean>(!props.isTeamLeader);
    const [emailMessage, setEmailMessage] = useState<string>('Email is required');
    const [phoneValid, setPhoneValid] = useState<boolean>(!props.isTeamLeader);
    const [phoneMessage, setPhoneMessage] = useState<string>('Phone number is required');

    const [onUpdateTimeout, setOnUpdateTimeout] = useState<any>(null);

    useEffect(() => {
        if (player.isTeamLeader || (!player.isTeamLeader && player.email && player.email.trim().length > 0)) {
            setEmailValid(false);

            const updatedPlayer = { ...player };
            updatedPlayer.emailIsValid = emailValid;
            handleUpdate(updatedPlayer);
            //
            // Check if email is already in use against the server
            //
            setEmailMessage('Checking email...');
        }

        const timer = setTimeout(async () => {
            if (player.isTeamLeader || (!player.isTeamLeader && player.email && player.email.trim().length > 0)) {
                const validation = validateEmail(player.email!);
                const emailIsValid = validation && validation.length > 0;

                if (!player.email || player.email.trim().length === 0) {
                    setEmailValid(false);
                    setEmailMessage('Email is required');
                } else if (!emailIsValid) {
                    setEmailValid(false);
                    setEmailMessage('Email is invalid');
                } else {
                    setEmailMessage('Checking email...');

                    fetch('/api/SignUp/User/' + player.email, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.status != 200) {
                            setEmailMessage('Error checking email');
                            return;
                        }

                        response.json().then(data => {
                            if (!data.success) {
                                setEmailValid(false);
                                setEmailMessage('Email is already in use');
                            } else {
                                setEmailValid(true);
                                setEmailMessage('');
                            }
                        });
                    });
                }
            } else {
                setEmailMessage('');
                setEmailValid(true);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [player.email]);

    useEffect(() => {
        const updatedPlayer = { ...player };
        updatedPlayer.emailIsValid = emailValid;
        handleUpdate(updatedPlayer);
    }, [emailValid]);

    useEffect(() => {
        const updatedPlayer = { ...player };
        updatedPlayer.phoneIsValid = phoneValid;
        handleUpdate(updatedPlayer);
    }, [phoneValid]);

    useEffect(() => {
        if (player.isTeamLeader || (!player.isTeamLeader && player.phoneNumber && player.phoneNumber.trim().length > 0)) {
            setPhoneValid(false);

            const updatedPlayer = { ...player };
            updatedPlayer.phoneIsValid = phoneValid;
            handleUpdate(updatedPlayer);
            //
            // Check if email is already in use against the server
            //
            setPhoneMessage('Checking phone number...');
        }

        const timer = setTimeout(async () => {
            if (player.isTeamLeader || (!player.isTeamLeader && player.phoneNumber && player.phoneNumber.trim().length > 0)) {
                const validation = validatePhone(player.phoneNumber!);
                const phoneIsValid = validation && validation.length > 0;

                if (!player.phoneNumber || player.phoneNumber.trim().length === 0) {
                    setPhoneValid(false);
                    setPhoneMessage('Phone number is required');
                } else if (!phoneIsValid) {
                    setPhoneValid(false);
                    setPhoneMessage('Phone number is invalid');
                } else {
                    setPhoneMessage('Checking phone number...');

                    fetch('/api/SignUp/User/Phone/' + player.phoneNumber, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.status !== 200) {
                            setPhoneMessage('Error checking phone number');
                            return;
                        }

                        response.json().then(data => {
                            if (!data.success) {
                                setPhoneValid(false);
                                setPhoneMessage('Phone number is already in use');
                            } else {
                                setPhoneValid(true);
                                setPhoneMessage('');
                            }
                        });
                    });
                }
            } else {
                setPhoneValid(true);
                setPhoneMessage('');
            }
            
        }, 1000);

        return () => clearTimeout(timer);
    }, [player.phoneNumber]);

    //
    // First name updated
    //
    const handleFirstNameUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const updatedPlayer = { ...player };
        updatedPlayer.firstName = inputValue;

        //
        // Notify parent
        //
        handleUpdate(updatedPlayer);
    }

    //
    // Last name updated
    //
    const handleLastNameUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const updatedPlayer = { ...player };
        updatedPlayer.lastName = inputValue;

        //
        // Notify parent
        //
        handleUpdate(updatedPlayer);
    }

    //
    // Phone updated
    //
    const handlePhoneUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const updatedPlayer = { ...player };
        updatedPlayer.phoneNumber = inputValue;

        //
        // Notify parent
        //
        handleUpdate(updatedPlayer);
    }

    //
    // Email updated
    //
    const handleEmailUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const updatedPlayer = { ...player };
        updatedPlayer.email = inputValue;

        //
        // Notify parent
        //
        handleUpdate(updatedPlayer);
    }

    //
    // Handle update - Slight delay before notifying parent
    //
    const handleUpdate = (updatedPlayer: PlayerSignUpModel) => {
        //
        // Team leader needs to have a valid email, first name, last name and shirt
        //
        if (updatedPlayer.isTeamLeader) {
            if (updatedPlayer.email
                && updatedPlayer.email.trim().length > 0
                && updatedPlayer.emailIsValid
                && updatedPlayer.phoneIsValid
                && updatedPlayer.firstName
                && updatedPlayer.firstName.trim().length > 0
                && updatedPlayer.lastName
                && updatedPlayer.lastName.trim().length > 0
                && updatedPlayer.shirtSize
                && updatedPlayer.shirtSize.trim().length > 0) {
                updatedPlayer.isValid = true;
            } else {
                updatedPlayer.isValid = false;
            }
        }

        //
        // Players need to have a valid first name, last name, and shirt
        //
        if (!updatedPlayer.isTeamLeader) {
            if (updatedPlayer.firstName
                && updatedPlayer.firstName.trim().length > 0
                && updatedPlayer.lastName
                && updatedPlayer.lastName.trim().length > 0
                && updatedPlayer.shirtSize
                && updatedPlayer.shirtSize.trim().length > 0
                && updatedPlayer.emailIsValid
                && updatedPlayer.phoneIsValid) {
                updatedPlayer.isValid = true;
            } else {
                updatedPlayer.isValid = false;
            }
        }

        const firstNameValid = updatedPlayer.firstName && updatedPlayer.firstName.trim().length > 0;
        setFirstNameValid(firstNameValid as boolean);

        const lastNameValid = updatedPlayer.lastName && updatedPlayer.lastName.trim().length > 0;
        setLastNameValid(lastNameValid as boolean);

        const shirtValid = updatedPlayer.shirtSize && updatedPlayer.shirtSize.trim().length > 0;
        setShirtValid(shirtValid as boolean);

        setPlayer(updatedPlayer);
        props.onUpdate(updatedPlayer);
    }

    //
    // Handle shirt size update
    //
    const handleShirtSizeUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const inputValue = (e.target as HTMLSelectElement).value;

        const updatedPlayer = { ...player };
        updatedPlayer.shirtSize = inputValue;

        //
        // Notify parent
        //
        handleUpdate(updatedPlayer);
    }

    let validStyles = { color: 'black', 'opacity': '0.3', fontSize: '2em' };

    if (player.isValid) {
        validStyles = { color: 'green', 'opacity': '1', fontSize: '2em' };
    }

    return (
        <>
            <div className="col-12 col-lg-6 pb-4">
                <div className="card" style={{ "height": "100%" }}>
                    <div className="card-header">
                        <div className="flex space-between align-center">
                            <div>
                                <div className="h5 card-title" style={{ marginBottom: '0' }}>
                                    Player #{player.playerNumber} {player.isTeamLeader && <span>(Team Leader)</span>}
                                </div>
                            </div>
                            <div>
                                <i style={validStyles} className="bi bi-check-circle-fill"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className="col-6">

                                <div className="form-floating my-2">
                                    <input
                                        className="form-control"
                                        name="first-name"
                                        defaultValue={player.firstName}
                                        onKeyUp={handleFirstNameUpdate}
                                    />
                                    <label className="form-label">First Name</label>
                                </div>
                                {!firstNameValid && <div className="required-input">
                                    First name is required
                                </div>}
                            </div>
                            <div className="col-6">
                                <div className="form-floating my-2">
                                    <input
                                        className="form-control"
                                        name="last-name"
                                        defaultValue={player.lastName}
                                        onKeyUp={handleLastNameUpdate}
                                    />
                                    <label className="form-label">Last Name</label>
                                </div>
                                {!lastNameValid && <div className="required-input">
                                    Last name is required
                                </div>}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating my-2">
                                    <input
                                        className="form-control form-control-sm"
                                        name="phone-number"
                                        type="text"
                                        defaultValue={player.phoneNumber}
                                        onKeyUp={handlePhoneUpdate}
                                    />
                                    <label className="form-label">Phone Number</label>
                                </div>
                                {!phoneValid && <div className="required-input">
                                    {phoneMessage}
                                </div>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating my-2">
                                    <input
                                        className="form-control form-control-sm"
                                        name="email"
                                        type="email"
                                        defaultValue={player.email}
                                        onKeyUp={handleEmailUpdate}

                                    />
                                    <label className="form-label">Email</label>
                                </div>
                                {!emailValid && <div className="required-input">
                                    {emailMessage}
                                </div>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating my-2">
                                    <select
                                        defaultValue={player.shirtSize}
                                        className="form-select"
                                        aria-label="T-Shirt Size"
                                        onChange={handleShirtSizeUpdate}
                                    >
                                        <option value=""></option>
                                        <option value="Small">Small</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Large">Large</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                        <option value="XXXL">XXXL</option>
                                    </select>
                                    <label htmlFor="floatingSelect">T-Shirt Size</label>
                                </div>
                                {!shirtValid && <div className="required-input">
                                    T-Shirt Size is required
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlayerSignUp;