import { ChangeEvent, useEffect, useState } from 'react';
import { useCurrentUser } from './../../contexts/UserContext';
import { useEvent } from './../../contexts/EventContext';
import Loading from '../shared/Loading';
import { getAuthHeader } from '../../helpers/authHelper';
import { showErrorAlert } from '../../helpers/alertHelper';
import { ShirtRequest } from '../../models/AttendeeSignUpRequest';

class ShirtSizeSummary {
    size: string = '';
    count: string = '';
}

const ShirtRequests = () => {
    //
    // Context
    //
    const currentUser = useCurrentUser();
    const event = useEvent();
    //
    // State
    //
    const [shirtRequests, setShirtRequests] = useState<ShirtRequest[] | undefined>([]);
    const [visibleShirtRequests, setVisibleShirtRequests] = useState<ShirtRequest[] | undefined>([]);
    const [summary, setSummary] = useState<ShirtSizeSummary[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(true);
    //
    // Use effect
    //
    useEffect(() => {
        const pageLoad = async () => {
            try {
                //
                // Get attendees from server
                //
                const resp = await fetch("/api/admin/get-shirt-requests", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getAuthHeader(),
                    }
                });

                if (resp.status !== 200) {
                    showErrorAlert();
                    console.error(resp);
                }

                const data = await resp.json();
                setShirtRequests(data.allRequests);
                setVisibleShirtRequests(data.allRequests);

                //
                // Create summary
                //
                const summary = new Map<string, number>();
                data.allRequests.forEach((shirtRequest: ShirtRequest) => {
                    if (summary.has(shirtRequest.size)) {
                        summary.set(shirtRequest.size, summary.get(shirtRequest.size)! + 1);
                    } else {

                        summary.set(shirtRequest.size, 1);
                    }
                });

                const summaryArray: ShirtSizeSummary[] = [];
                summary.forEach((value, key) => {
                    const summaryItem = new ShirtSizeSummary();
                    summaryItem.size = key;
                    summaryItem.count = value.toString();
                    summaryArray.push(summaryItem);
                });

                setSummary(summaryArray);
            } catch {
                showErrorAlert();
                return;
            } finally {
                setLoading(false);
            }
        }

        pageLoad();
    }, []);

    //
    // Handle search
    //
    function handleSearch(e: ChangeEvent<HTMLInputElement>): void {

        const search = e.target.value.toLowerCase();

        const filteredShirtRequests = shirtRequests!.filter((shirtRequest) => {
            if (shirtRequest.size!.toString().toLowerCase().includes(search)) {
                return true;
            }

            if (shirtRequest.requestedByEmailAddress!.toString().toLowerCase().includes(search)) {
                return true;
            }

            return false;
        });

        setVisibleShirtRequests(filteredShirtRequests);
    }

    return (
        <div className="container p-3">
            <h2>Shirt Requests</h2>

            {loading && <Loading />}

            {!loading && <>


                {summary && summary.length > 0 && <div className="row">
                    <div className="col-12">
                        <h3>Summary</h3>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Size</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary.map((summaryItem, index) => {

                                        return (

                                            <tr key={index}>
                                                <td>{summaryItem.size}</td>
                                                <td>{summaryItem.count}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td style={{ textAlign: 'right' }}><b>Total</b></td>
                                        <td><b>{summary.reduce((acc, summaryItem) => acc + parseInt(summaryItem.count), 0)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}

                <div className="my-3 row ml-3 mr-3">
                    <div className="col-12">
                        <h3>Details</h3>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text search-input-icon py-2">
                                    <i className="bi bi-search"></i>
                                </div>
                            </div>
                            <input
                                className="form-control py-2 border-right-0 border"
                                type="search"
                                placeholder="Search"
                                onChange={(e) => { handleSearch(e) }}
                            />
                        </div>
                    </div>
                </div>

                {visibleShirtRequests && visibleShirtRequests.length > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Size</th>
                                            <th>Type</th>
                                            <th>Requested By</th>
                                            <th>Is Paid</th>
                                            <th>Created On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visibleShirtRequests.map((attendee, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>{attendee.size}</td>
                                                    <td>{attendee.type}</td>
                                                    <td>{attendee.requestedByEmailAddress}</td>
                                                    <td>{attendee.isPaid ? "Yes" : "No"}</td>
                                                    <td>{attendee.createdOnFormatted}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
            </>}
        </div>
    )
}

export default ShirtRequests;