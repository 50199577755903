// @ts-nocheck
import React, { useState, useContext } from 'react';
import CurrentUserModel from '../models/CurrentUserModel';
import { LOCAL_STORAGE_USER } from '../helpers/constants';

const UserContext = React.createContext(null);
const UserUpdateContext = React.createContext<(user: CurrentUserModel | undefined) => void | null>(null);

export function useCurrentUser(): CurrentUserModel | undefined {
    return useContext(UserContext);
}

export function useUpdateCurrentUser(): (user: CurrentUserModel | undefined, source?: string) => void {
    return useContext(UserUpdateContext);
}

export function UserProvider({ children }) {
    const [user, setUser] = useState<CurrentUserModel | undefined>();

    function updateUser(newUser: CurrentUserModel | undefined, source: string = '') {
        if (newUser && newUser !== undefined && newUser !== null) {
            //console.log('source: ' + source, newUser);
            localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(newUser));
        }

        setUser(newUser);
    }

    return (
        <UserContext.Provider value={user}>
            <UserUpdateContext.Provider value={updateUser}>
                {children}
            </UserUpdateContext.Provider>
        </UserContext.Provider>
    );
}