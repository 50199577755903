import { useEffect, useState } from "react";
import Loading from "../shared/Loading";
import { useNavigate, useParams } from "react-router-dom";
import LoginResponseModel from "../../models/LoginResponseModel";
import CurrentUserModel from "../../models/CurrentUserModel";
import { useUpdateCurrentUser } from "../../contexts/UserContext";
import { useUpdatePayment } from "../../contexts/PaymentContext";
import { setAuth } from "../../helpers/authHelper";
import TeamLogin from "./TeamLogin";
import PaymentModel from "../../models/PaymentModel";
import { LOCAL_STORAGE_USER } from "../../helpers/constants";

const TeamAuth = () => {
    //
    // Context
    //
    const updateCurrentUser = useUpdateCurrentUser();
    const updatePayment = useUpdatePayment();
    //
    // Constants
    //
    const navigate = useNavigate();
    let { authCode } = useParams();
    //
    // State
    //
    const [error, setError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        fetch('/api/Auth/Login/ValidateTeamAuthRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ authCode: authCode })
        })
            //
            // Handle invalid json response
            //
            .then(async (response) => {
                if (!response.ok) {
                    setError(true);
                    setMessage(await response.text());
                    throw new Error("Invalid response from server");
                }

                return response.json();
            })
            .then((data: LoginResponseModel) => {
                var user = new CurrentUserModel();
                user.roles = data.roles;
                user.teamId = data.teamId;
                user.name = data.name;
                user.firstName = data.firstName;
                user.lastName = data.lastName;
                user.teamHasPaid = data.teamHasPaid;
                user.email = data.email;

                if (data.roles.includes('Admin')) {
                    user.isAdmin = true;
                }
                
                updateCurrentUser(user, 'TeamAuth.tsx');
                //localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));

                if (user.teamHasPaid === false) {
                    const newPayment = new PaymentModel();
                    newPayment.isTeamRequest = true;
                    newPayment.requestedByEmail = data.email;
                    newPayment.requestedByFirstName = data.firstName;
                    newPayment.requestedByLastName = data.lastName;
                    updatePayment(newPayment);
                }

                setAuth(data.email, data.jwt, data.validTo);
                navigate("/live-scoring");
            });
    }, [updateCurrentUser]);

    if (error) return (
        <div className="p-2">
            <div className="alert alert-danger" role="alert">
                <h5 style={{ "marginBottom": "0" }}>{message}</h5>
            </div>

            <TeamLogin />
        </div>
    );

    return (
        <div>
            <Loading message="Authenticating..." />
        </div>
    )
}

export default TeamAuth;