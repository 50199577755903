import { ChangeEvent, useEffect, useState } from "react";
import StandingsModel from "../../models/StandingsModel";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../../contexts/UserContext";
import CurrentTeam from "../shared/CurrentTeam";
import Loading from "../shared/Loading";
import CurrentUserModel from "../../models/CurrentUserModel";
import { getCurrentDateAndTime } from "../../helpers/dateHelper";
import useInterval from "../../hooks/useInterval";
import { useEvent } from "../../contexts/EventContext";

// Purpose: To display the standings of the golf league
const Standings = () => {
    //
    // Context
    //
    const user: CurrentUserModel | undefined = useCurrentUser();
    //
    // State
    //
    const [standings, setStandings] = useState<StandingsModel[]>([]);
    const [visibleStandings, setVisibleStandings] = useState<StandingsModel[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [expandedTeams, setExpandedTeams] = useState<number[]>([]);
    const [refreshDateTime, setRefreshDateTime] = useState<string>(getCurrentDateAndTime());

    const currentEvent = useEvent();

    // Get teams on page load
    useEffect(() => {
        // Get teams from the server
        refreshStandings();
    }, []);

    useEffect(() => {
        if (searchText && searchText.length > 0) {
            runSearch(searchText);
        }
    }, [standings]);

    useInterval(() => {
        refreshStandings();
    }, 10000);

    const refreshStandings = () => {
        // Get teams from the server
        fetch("/api/Golf/GetStandings")
            .then((response) => response.json())
            .then((json) => {
                json.forEach((standing: StandingsModel) => {
                    if (expandedTeams.includes(standing.teamId)) {
                        standing.showTeamMembers = true;
                    }
                });

                setStandings(json)
                setVisibleStandings(json);
                setRefreshDateTime(getCurrentDateAndTime());
            });
    }

    if (!standings) {
        return (
            <div className="m-2">
                <Loading />
            </div>
        );
    }

    // If teams are not loaded, return a "no teams..." message
    if (standings?.length === 0) {
        return (
            <div className="m-2">
                <Loading />
            </div>
        );
    }

    function handleSearch(e: ChangeEvent<HTMLInputElement>): void {
        //// Search for a team by team number, team leader name, or team member name
        const search = e.target.value.toLowerCase();
        setSearchText(search);
        runSearch(search);
    }

    //
    // Run the search
    //
    function runSearch(search: string) {
        const filteredTeams = standings.filter((standing) => {

            if (standing.teamMembers?.some((member) => member?.toLowerCase().includes(search))) {
                return true;
            }

            if (standing.teamName?.toLowerCase().includes(search)) {
                return true;
            }

            return false;
        });

        setVisibleStandings(filteredTeams);
    }

    //
    // Toggle team member visibility
    //
    function toggleTeamMembers(team: StandingsModel): void {
        const updatedTeams = expandedTeams.slice();

        const updatedStandings = standings.map((standing) => {
            if (standing.teamName === team.teamName) {
                standing.showTeamMembers = !standing.showTeamMembers;

                //
                // Add or remove the team from the list of expanded teams
                //
                if (standing.showTeamMembers) {
                    updatedTeams.push(standing.teamId);
                } else {
                    if (updatedTeams.includes(standing.teamId)) {
                        updatedTeams.splice(updatedTeams.indexOf(standing.teamId), 1);
                    }
                }
            }

            return standing;
        });

        const set = new Set(updatedTeams);
        const uniqueArray = Array.from(set)

        setExpandedTeams(uniqueArray);
        setStandings(updatedStandings);
    }

    return (
        <>
            <div className="py-lg-3 py-md-2 py-sm-2 px-2 mt-2 ctx-menu-page narrow-page">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h2 className="text-dark">Leaderboard</h2>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Link to="/teams" className="btn btn-primary d-flex align-items-center me-2">
                            <i className="bi bi-people me-2"></i> Teams
                        </Link>
                        <Link to="/" className="btn btn-primary d-flex align-items-center">
                            <i className="bi bi-house me-2"></i> Home
                        </Link>
                    </div>
                </div>
                <hr />
                <p>Click on each row to see all team members.</p>
                <div className="alert alert-info">
                    <b>*Scores will refresh every 10 seconds*</b>
                    <div>
                        Last refreshed {refreshDateTime}
                    </div>
                </div>
                <div className="my-3 row ml-3 mr-3">
                    <div className="col-12">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text search-input-icon py-2">
                                    <i className="bi bi-search"></i>
                                </div>
                            </div>
                            <input
                                className="form-control py-2 border-right-0 border"
                                type="search"
                                placeholder="Search"
                                autoFocus
                                onChange={(e) => handleSearch(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="table-responsive" style={{ "maxWidth": "100%" }}>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th style={{ "maxWidth": "5%" }}>POS</th>
                                <th style={{ "maxWidth": "50%" }}>TEAM</th>
                                <th>SCORE</th>
                                <th>THRU</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visibleStandings.map((team, index) => {
                                const isCurrentTeam = user?.teamId === team.teamId;
                                var currentTeamStyle = isCurrentTeam ? { "backgroundColor": "rgb(255 216 76)" } : {};

                                return (
                                    <tr className="clickable" style={currentTeamStyle} key={index} onClick={() => toggleTeamMembers(team)}>
                                        <td>{team.position}</td>
                                        <td>
                                            {team.teamName}
                                            {team.showTeamMembers && <div>
                                                {team.teamMembers.map((member, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {member}
                                                        </div>
                                                    );
                                                })}
                                            </div>}
                                        </td>
                                        <td>{team.currentScoreDisplay}</td>
                                        <td>{team.holesCompleted}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {user && currentEvent && !currentEvent.scoringIsLocked && (
                <CurrentTeam showLink={true} linkText="View Live Scoring" linkUrl="/live-scoring" />
            )}
        </>
    );
}

export default Standings;