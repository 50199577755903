// @ts-nocheck
import React, { useState, useContext } from 'react';
import EventDetailsModel from '../models/EventDetailsModel';
import { LOCAL_STORAGE_EVENT } from '../helpers/constants';

const EventContext = React.createContext(null);
const EventUpdateContext = React.createContext<(event: EventDetailsModel) => void | null>(null);

export function useEvent(): EventDetailsModel {
    return useContext(EventContext);
}

export function useUpdateEvent(): (event: EventDetailsModel) => void {
    return useContext(EventUpdateContext);
}

export function EventProvider({ children }) {
    const [event, setEvent] = useState<EventDetailsModel>();

    function updateEvent(newEvent: EventDetailsModel) {
        localStorage.setItem(LOCAL_STORAGE_EVENT, JSON.stringify(newEvent));
        setEvent(newEvent);
    }

    return (
        <EventContext.Provider value={event}>
            <EventUpdateContext.Provider value={updateEvent}>
                {children}
            </EventUpdateContext.Provider>
        </EventContext.Provider>
    );
}