interface Props {
    message?: string;
}

const Loading = (props: Props) => {
    return (
        <div className="loading-wrapper p-3">
            <div>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <div className="loading-message">
                    {props.message ?? 'Loading...'}
                </div>
            </div>
        </div>
    )
}

export default Loading;