// Component that shows all teams and members
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { toast } from "react-hot-toast";
import { useSearchParams } from 'react-router-dom';

import TeamModel from "../../models/TeamModel";
import TextMessageRequest from "../../models/TextMessageRequest";
import Loading from "../shared/Loading";
import { useCurrentUser } from "../../contexts/UserContext";
import { getAuthHeader } from "../../helpers/authHelper";


const TeamInfoAdmin = () => {
    //
    // Context
    //
    const user = useCurrentUser();
    //
    // State
    //
    const navigate = useNavigate();
    const [teams, setTeams] = useState<TeamModel[]>();
    const [visibleTeams, setVisibleTeams] = useState<TeamModel[]>();
    const [searchParams] = useSearchParams();
    const [sendingBlast, setSendingBlast] = useState(false);

    // Get teams on page load
    useEffect(() => {
        // Get teams from the server
        fetch("/api/Admin/GetTeams", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            }
        })
            .then((response) => response.json())
            .then((json) => {
                setTeams(json)
                setVisibleTeams(json);
            });

        //
        // If we route to this page with query params, and one of the is "redirect_status" with a status
        // of "succeeded", we routed here from a successful payment.  Show a toast message.
        //
        if (searchParams !== undefined && searchParams.get('redirect_status') !== undefined &&
            searchParams.get('redirect_status') == "succeeded") {
            toast.success("Team registered successfully!", {
                duration: 5000
            });
        }

    }, []);

    //
    // Get the name of the team leader
    //
    const getTeamLeaderName = (team: TeamModel) => {
        if (!team.teamLeader) {
            return "No team leader";
        }

        return team.teamLeader.firstName + " " + team.teamLeader.lastName;
    }

    if (!teams) {
        return (
            <div className="m-2">
                <Loading />
            </div>
        );
    }

    // If teams are not loaded, return a "no teams..." message
    if (teams?.length === 0) {
        return <h2>No teams found</h2>;
    }

    //
    // Search for a team by team number, team leader name, or team member name
    //
    function handleSearch(e: ChangeEvent<HTMLInputElement>): void {
        //// Search for a team by team number, team leader name, or team member name
        const search = e.target.value.toLowerCase();

        const filteredTeams = teams!.filter((team) => {
            if (team.teamNumber!.toString().includes(search)) {
                return true;
            }

            if (team.teamMembers?.some((member) => member.fullName!.toLowerCase().includes(search))) {
                return true;
            }

            if (team.teamLeader?.fullName!.toLowerCase().includes(search)) {
                return true;
            }

            return false;
        });

        setVisibleTeams(filteredTeams);
    }

    const handleEditTeamClick = (teamId: number) => {
        //navigate('/admin/score-entry', { state: { teamId: teamId } });
        navigate('/admin/score-entry/' + teamId);
    }

    const setTeamPayment = (team: TeamModel) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will update the team as paid and allow them into live scoring. Typically this button would only be used if a team needs to pay at the event through Venmo, etc.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Set Team Payment'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch("/api/Admin/SetTeamPayment", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': getAuthHeader()
                    },
                    body: JSON.stringify(team)
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.success) {
                            toast.success("Team payment amount updated!", {
                                duration: 5000
                            });

                            // Reload teams
                            fetch("/api/Admin/GetTeams", {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': getAuthHeader(),
                                }
                            })
                                .then((response) => response.json())
                                .then((json) => {
                                    setTeams(json)
                                    setVisibleTeams(json);
                                });
                        } else {
                            toast.error("Error setting team payment: " + json.message, {
                                duration: 5000
                            });
                        }
                    });
            }
        })
    }

    //
    // Send Team Text Invite
    //
    const sendTextInvite = (team: TeamModel) => {
        fetch("/api/Admin/SendTextInvite", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': getAuthHeader()
            },
            body: JSON.stringify(team)
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.success) {
                    toast.success("Text invite sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending text invite: " + json.message, {
                        duration: 5000
                    });
                }
            });
    }

    //
    // Send payment request email
    //
    const sendPaymentRequestEmail = (team: TeamModel) => {
        fetch("/api/Admin/SendPaymentRequestEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': getAuthHeader()
            },
            body: JSON.stringify(team)
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.success) {
                    toast.success("Payment request email sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending payment request email: " + json.message, {
                        duration: 5000
                    });
                }
            });
    }

    //
    // Send invite blast to all teams
    //
    const sendInviteBlast = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will email all teams",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Invite Blast'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSendingBlast(true);

                const response = await fetch("/api/Admin/SendTeamInviteBlastEmail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': getAuthHeader()
                    }
                });

                const json = await response.json();

                setSendingBlast(false);

                if (json.success) {
                    toast.success("Invite blast sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending invite blast: " + json.message, {
                        duration: 5000
                    });
                }
            }
        })
    }

    const sendTextMessage = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will text all teams",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Text Blast',
            html: `
            <textarea id="textMessage" class="swal2-textarea" placeholder="Type your message here..."></textarea>
        `
        }).then(async (result) => {
            if (result.isConfirmed) {
                const messageInput = document.getElementById('textMessage') as HTMLTextAreaElement;
                const message = messageInput.value;

                const textMessageRequest = new TextMessageRequest(message);

                setSendingBlast(true);

                const response = await fetch("/api/Admin/SendTextMessageBlast", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': getAuthHeader()
                    },
                    body: JSON.stringify(textMessageRequest) // Pass the message to the API
                });

                const json = await response.json();

                setSendingBlast(false);

                if (json.success) {
                    toast.success("Text blast sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending text blast: " + json.message, {
                        duration: 5000
                    });
                }
            }
        })
    }

    //
    // Send event reminder email to all users with emails
    //
    const sendReminderBlast = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will email all team members with emails in the system with event information",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Reminder Blast'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSendingBlast(true);

                const response = await fetch("/api/Admin/SendReminderEmail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': getAuthHeader()
                    }
                });

                const json = await response.json();

                setSendingBlast(false);

                if (json.success) {
                    toast.success("Invite blast sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending invite blast: " + json.message, {
                        duration: 5000
                    });
                }
            }
        })
    }

    //
    // Send payment request blast to all teams
    //
    function sendPaymentBlast(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will email all teams that have not paid",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Payment Blast'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSendingBlast(true);

                const response = await fetch("/api/Admin/SendPaymentBlastEmail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': getAuthHeader()
                    }
                });

                const json = await response.json();

                setSendingBlast(false);

                if (json.success) {
                    toast.success("Payment blast sent successfully!", {
                        duration: 5000
                    });
                } else {
                    toast.error("Error sending payment blast: " + json.message, {
                        duration: 5000
                    });
                }
            }
        })
    }

    // Handle the Phone Number Change
    const handlePhoneNumberChange = async (e: any, team: TeamModel) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const phoneNumber = inputValue;

        const body = {
            teamId: team.id,
            phoneNumber: phoneNumber
        };

        fetch("/api/Admin/SetPhoneNumber", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((json) => {
                fetch("/api/Admin/GetTeams", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getAuthHeader(),
                    }
                })
                    .then((response) => response.json())
                    .then((json) => {
                        setTeams(json)
                        setVisibleTeams(json);
                    });
            });
    }

    //
    // Handle the starting hole change
    //
    const handleStartingHoleChange = async (e: any, team: TeamModel) => {
        const inputValue = (e.target as HTMLInputElement).value;

        const startingHole = parseInt(inputValue);

        const body = {
            teamId: team.id,
            startingHole: startingHole
        };

        fetch("/api/Admin/SetStartingHole", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(),
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((json) => {
                fetch("/api/Admin/GetTeams", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getAuthHeader(),
                    }
                })
                    .then((response) => response.json())
                    .then((json) => {
                        setTeams(json)
                        setVisibleTeams(json);
                    });
            });
    }

    if (sendingBlast) {
        return <Loading message="Sending invite blast..." />;
    }

    return (
        <div className="py-lg-3 py-md-2 py-sm-2 px-2">
            <h2>Teams</h2>
            <div>
                <button className="btn btn-success" onClick={sendInviteBlast}>Send Invite Blast</button>
                <button className="btn btn-success ms-3" onClick={sendTextMessage}>Send Text Message</button>
                {/*<button className="btn btn-danger ms-3" onClick={sendPaymentBlast}>Send Payment Request Emails</button>*/}
                {/*<button className="btn btn-danger ms-3" onClick={sendReminderBlast}>Send Reminder Emails</button>*/}
            </div>
            <div className="my-3 row ml-3 mr-3">
                <div className="col-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text search-input-icon py-2">
                                <i className="bi bi-search"></i>
                            </div>
                        </div>
                        <input
                            className="form-control py-2 border-right-0 border"
                            type="search"
                            placeholder="Search"
                            autoFocus
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="row ml-1 mr-1">
                {visibleTeams!.map((team) => (
                    <div key={team.id} className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="flex space-between align-center">
                                    <div className="flex-grow-1 pe-2">
                                        <div className="h5 card-title" style={{ marginBottom: '0' }}>
                                            <div className="flex space-between align-center ">
                                                <div>
                                                    Team #{team.teamNumber}
                                                </div>
                                                <div className="alert alert-dark" style={{ "marginBottom": 0, "padding": "5px" }}>
                                                    {team.teamCode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {user && user.isAdmin && <div>
                                        <i onClick={() => { handleEditTeamClick(team.id) }} style={{ cursor: "pointer", "fontSize": "24px" }} className="bi bi-pencil-square"></i>
                                    </div>}
                                </div>
                            </div>
                            <div className="card-body">
                                {team.teamMembers?.map((member) => {
                                    if (member.isTeamLeader) {
                                        return (<div className="pb-3">
                                            <h5 key={member.id} className="card-title">{member.fullName}</h5>
                                            <span>({member.email})</span>
                                        </div>)
                                    }

                                    return (
                                        <div key={member.id}>{member.fullName}</div>
                                    )
                                })}
                                <div>
                                    <input
                                        className="form-control"
                                        name="phoneNumber"
                                        type="string"
                                        defaultValue={team.phoneNumber}
                                        onBlur={(e) => handlePhoneNumberChange(e, team)}
                                    />
                                    <label className="form-label">Phone Number</label>
                                </div>
                                <div>
                                    <button className="btn btn-primary mt-1 mb-2" onClick={() => sendTextInvite(team)}>
                                        Send Text Invite
                                    </button>
                                </div>
                                <div>
                                    <input
                                        className="form-control"
                                        name="score"
                                        type="number"
                                        defaultValue={team.startingHole}
                                        onChange={(e) => handleStartingHoleChange(e, team)}
                                    />
                                    <label className="form-label">Starting Hole</label>
                                </div>
                                {!team.hasPaidInFull && <div>
                                    <div className="alert alert-danger mt-2" role="alert" style={{ marginBottom: 0 }}>
                                        <i className="bi bi-exclamation-triangle"></i> Team has not paid in full
                                        <div>
                                            Amount Paid: ${team.paidAmount}
                                        </div>
                                        <div>
                                            <button className="btn btn-primary mt-2" onClick={() => sendPaymentRequestEmail(team)}>
                                                Send payment request email
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary mt-2" onClick={() => setTeamPayment(team)}>Set Team Payment</button>
                                    </div>
                                </div>}
                                {team.scoreConfirmed && <div className="alert alert-success mt-2" role="alert" style={{ marginBottom: 0 }}>
                                    <i className="bi bi-check-circle"></i> Score confirmed
                                </div>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeamInfoAdmin;
